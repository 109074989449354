import React, { createContext, useState } from 'react';
import { AllLessons, exerciseDesc, lessonHeader } from '../models/Tasks';

const ExercContext = createContext();

const ExercContextProvider = ({children}) => {

    const [mistakesCount, setMistakesCount] = useState(localStorage.getItem('mistakeCounter') ? +localStorage.getItem('mistakeCounter') : 0);
    //const [mistakesCount, setMistakesCount] = useState(0);
   
    const [typesPerMinute, setTypesPerMinute] = useState(localStorage.getItem('tpm') ? +localStorage.getItem('tpm') : 0);
    const [lessonExercPassed, setLessonExercPassed] = useState(localStorage.getItem('lessonExcercPassed') ? +localStorage.getItem('lessonExcercPassed') : 0);
    const [starsCount, setStarsCount] = useState(+localStorage.getItem('starsCount'));
    const [cursPos, setCursPos] = useState(() => {return 0});

    const [rptExrc, setRptExrc] = useState(null);

    
   const [lessonCount, setLessonCount] = useState(1); 
   const [exerciseCount, setExerciseCount] = useState(1);

   const [lessonStarted, setLessonStarted] = useState(false);

   const lesson = AllLessons; 
   const exercDesc = exerciseDesc;
   const lsnHeader = lessonHeader;
   const numberOfExerc = lesson[+lessonCount - 1]?.length;
   
    return (
        <ExercContext.Provider
        value={
            {
                mistakesCount, setMistakesCount,
                starsCount, setStarsCount,
                cursPos, setCursPos,
                typesPerMinute, setTypesPerMinute,
                lessonCount, setLessonCount,
                exerciseCount, setExerciseCount,
                lessonStarted, setLessonStarted,
                lessonExercPassed, setLessonExercPassed,
                lesson,
                exercDesc,
                numberOfExerc,
                rptExrc, setRptExrc,
                lsnHeader,
            }
        }
        >
             {children}
        </ExercContext.Provider>
    )
}

export { ExercContextProvider, ExercContext }