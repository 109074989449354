import React from 'react';
import c from './typing_sc_header.module.css';

import { FormattedMessage } from 'react-intl';
import defineMessages from '../Lang/languages/messages';



const TypingScreenHeader = (props) => {


  return (
       <div className={c.typing_screen_header}>
          <div className={c.typing_screen_header_container}>
            <div>
              <div className={c.typing_screen_header_box1}>
               <div className={c.typing_screen_header_item}>
               <FormattedMessage id='superPower' defaultMessage={defineMessages.superPower} />
                <b> {props.lessonCount}</b>
               </div>
                 <span>
                 <FormattedMessage id='exerc' defaultMessage={defineMessages.exerc} /> <b> {props.exerciseCount}</b>
                 </span>
               </div>
              <span className={c.lesson_header_three}>
                <FormattedMessage id='errors' defaultMessage={defineMessages.errors} /> - {props.mistakesCount}
              </span>
            </div>            
            <div className={c.typing_screen_header_box2}>
              <div className={c.lesson_header_three}>
              <FormattedMessage id='time' defaultMessage={defineMessages.time} /> 
               <div className={c.timeCounter}>{props.timeElapsed}</div> 
               <FormattedMessage id='sec' defaultMessage={defineMessages.sec} /> 
              </div>
            </div>            
          </div>
        </div>
  )
}

export default TypingScreenHeader