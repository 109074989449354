import React, { useState, } from 'react';
import c from './keyboard.module.css';
import useBg from '../Hooks/useBg';

import { FormattedMessage } from 'react-intl';
import defineMessages from '../Lang/languages/messages';



const KeyBoard = ( { value, expected, expVal, error } ) => {


const {oliveBg, greenBtn, blueBtn, rozeBtn, orangeBtn, yellowBtn, } = useBg();



const [showControll, setShowControll] = useState(localStorage.getItem('showKeyBoard'));

const handleShowControll = () => {
 if(showControll === 'hideKeyBoard') {
  localStorage.setItem('showKeyBoard', 'showKeyBoard');
  setShowControll('showKeyBoard');
 } else {
  localStorage.setItem('showKeyBoard', 'hideKeyBoard');
  setShowControll('hideKeyBoard');
 }
};

    //console.log(1, value, 2, expVal, 3, expected);
  return (
    <div className={c.key_board_container}>
     <div className={c.key_board_wrapper}>
      {showControll !== 'hideKeyBoard' && <>
      
        <div className={`${c.key_board_row} `}>
            <div className={`${c.key_board_btn} ${oliveBg}`}>{'`'}</div>
            <div className={`${c.key_board_btn} ${greenBtn} ${expVal === '1' && c.exp_btn}`}>1</div>
            <div className={`${c.key_board_btn} ${greenBtn} ${expVal === '2' && c.exp_btn}`}>2</div>
            <div className={`${c.key_board_btn} ${blueBtn} ${expVal === '3' && c.exp_btn}`}>3</div>
            <div className={`${c.key_board_btn} ${rozeBtn} ${expVal === '4' && c.exp_btn}`}>4</div>
            <div className={`${c.key_board_btn} ${orangeBtn} ${expVal === '5' && c.exp_btn}`}>5</div>
            <div className={`${c.key_board_btn} ${orangeBtn} ${expVal === '6' && c.exp_btn}`}>6</div>
            <div className={`${c.key_board_btn} ${yellowBtn} ${expVal === '7' && c.exp_btn}`}>7</div>
            <div className={`${c.key_board_btn} ${rozeBtn} ${expVal === '8' && c.exp_btn}`}>8</div>
            <div className={`${c.key_board_btn} ${blueBtn} ${expVal === '9' && c.exp_btn}`}>9</div>
            <div className={`${c.key_board_btn} ${greenBtn} ${expVal === '0' && c.exp_btn}`}>0</div>
            <div className={`${c.key_board_btn} ${greenBtn} ${expVal === '-' && c.exp_btn}`}>-</div>
            <div className={`${c.key_board_btn} ${greenBtn} ${expVal === '=' && c.exp_btn}`}>=</div>
            <div className={`${c.key_board_btn} ${c.key_board_btn1} ${oliveBg}`}>Bck</div>
        </div>

        <div className={c.key_board_row}>
            <div className={`${c.key_board_btn} ${c.key_board_btn1} ${oliveBg}`}>TAB</div>
            <div className={`${c.key_board_btn} ${greenBtn} ${expVal === 'q' && c.exp_btn}`}>Q</div>
            <div className={`${c.key_board_btn} ${blueBtn} ${expVal === 'w' && c.exp_btn}`}>W</div>
            <div className={`${c.key_board_btn} ${rozeBtn} ${expVal === 'e' && c.exp_btn}`}>E</div>
            <div className={`${c.key_board_btn} ${orangeBtn} ${expVal === 'r' && c.exp_btn}`}>R</div>
            <div className={`${c.key_board_btn} ${orangeBtn} ${expVal === 't' && c.exp_btn}`}>T</div>
            <div className={`${c.key_board_btn} ${yellowBtn} ${expVal === 'y' && c.exp_btn}`}>Y</div>
            <div className={`${c.key_board_btn} ${yellowBtn} ${expVal === 'u' && c.exp_btn}`}>U</div>
            <div className={`${c.key_board_btn} ${rozeBtn} ${expVal === 'i' && c.exp_btn}`}>I</div>
            <div className={`${c.key_board_btn} ${blueBtn} ${expVal === 'o' && c.exp_btn}`}>O</div>
            <div className={`${c.key_board_btn} ${greenBtn} ${expVal === 'p' && c.exp_btn}`}>P</div>
            <div className={`${c.key_board_btn} ${greenBtn} ${expVal === '[' && c.exp_btn}`}>{'['}</div>
            <div className={`${c.key_board_btn} ${greenBtn} ${expVal === ']' && c.exp_btn}`}>{']'}</div>
            <div className={`${c.key_board_btn} ${oliveBg}`}>\</div>           
        </div>

        <div className={c.key_board_row}>
            <div className={`${c.key_board_btn} ${c.key_board_btn2} ${oliveBg}`}>CAPS</div>
            <div className={`${c.key_board_btn} ${greenBtn} ${expVal === 'a' && c.exp_btn}`}>A</div>
            <div className={`${c.key_board_btn} ${blueBtn} ${expVal === 's' && c.exp_btn}`}>S</div>
            <div className={`${c.key_board_btn} ${rozeBtn} ${expVal === 'd' && c.exp_btn}`}>D</div>
            <div className={c.key_board_abs_cont}>
               <div className={`${c.key_board_btn} ${orangeBtn} ${expVal === 'f' && c.exp_btn}`}>F</div>
               <div className={c.key_board_abs_underline}>_</div> 
            </div>            
            <div className={`${c.key_board_btn} ${orangeBtn} ${expVal === 'g' && c.exp_btn}`}>G</div>
            <div className={`${c.key_board_btn} ${yellowBtn} ${expVal === 'h' && c.exp_btn}`}>H</div>
            <div className={c.key_board_abs_cont}>
               <div className={`${c.key_board_btn} ${yellowBtn} ${expVal === 'j' && c.exp_btn}`}>J</div>
               <div className={c.key_board_abs_underline}>_</div> 
            </div>            
            <div className={`${c.key_board_btn} ${rozeBtn} ${expVal === 'k' && c.exp_btn}`}>K</div>
            <div className={`${c.key_board_btn} ${blueBtn} ${expVal === 'l' && c.exp_btn}`}>L</div>
            <div className={`${c.key_board_btn} ${greenBtn} ${expVal === ';' && c.exp_btn}`}>;</div>
            <div className={`${c.key_board_btn} ${greenBtn} ${expVal === `'` && c.exp_btn}`}>{`'`}</div>
            <div className={`${c.key_board_btn} ${c.key_board_btn2} ${oliveBg}`}>ENTER</div>           
        </div>

        <div className={c.key_board_row}>
            <div className={`${c.key_board_btn} ${c.key_board_btn3} ${oliveBg}`}>SHIFT</div>           
            <div className={`${c.key_board_btn} ${greenBtn} ${expVal === 'z' && c.exp_btn}`}>Z</div>
            <div className={`${c.key_board_btn} ${blueBtn} ${expVal === 'x' && c.exp_btn}`}>X</div>
            <div className={`${c.key_board_btn} ${rozeBtn} ${expVal === 'c' && c.exp_btn}`}>C</div>
            <div className={`${c.key_board_btn} ${orangeBtn} ${expVal === 'v' && c.exp_btn}`}>V</div>
            <div className={`${c.key_board_btn} ${orangeBtn} ${expVal === 'b' && c.exp_btn}`}>B</div>
            <div className={`${c.key_board_btn} ${yellowBtn} ${expVal === 'n' && c.exp_btn}`}>N</div>
            <div className={`${c.key_board_btn} ${yellowBtn} ${expVal === 'm' && c.exp_btn}`}>M</div>
            <div className={`${c.key_board_btn} ${rozeBtn} ${expVal === ',' && c.exp_btn}`}>,</div>
            <div className={`${c.key_board_btn} ${blueBtn} ${expVal === '.' && c.exp_btn}`}>.</div>
            <div className={`${c.key_board_btn} ${greenBtn} ${expVal === '/' && c.exp_btn}`}>{`/`}</div>
            <div className={`${c.key_board_btn} ${c.key_board_btn3} ${oliveBg}`}>SHIFT</div>           
        </div>

        <div className={`${c.key_board_row} ${c.low_row}`}>
         <div className={`${c.key_board_btn} ${c.key_board_btn4} ${oliveBg} ${expVal === 'Space' && c.exp_btn}`}/>
       
        </div> 
      </>}
     </div>    
      {error && <div className={c.keyboard_error}/>}
      <div className={c.show_controll} onClick={() => handleShowControll()}>
        {showControll === 'hideKeyBoard' ? 
        <FormattedMessage id='ShowKeyBoard' defaultMessage={defineMessages.ShowKeyBoard} />        
        : 
        <FormattedMessage id='keyBoard' defaultMessage={defineMessages.keyBoard} />        
        }
      </div>
    </div>
  )
}

export default KeyBoard



//useEffect(() => {
  // if(expVal !== 'Space') {
  //   if(value && value !== expVal) {
  //       alert('failure');
  //   } 
  // //   if(value && value === expVal) {
  // //       alert('yes');
  // //   }  
  // } else if(expVal === 'Space') {
  //     if(value &&  value !== ' ') {
  //         alert('failure-2');
  //     } 
  //     // if(value && value === ' ') {
  //     //     alert('yes-2');
  //     // } a
  // }
// eslint-disable-next-line
//},[value]);