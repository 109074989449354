import _ from "lodash";


export const handleStarsCount = (mistakesCount, setStarsCount) => {
  //console.log('mistakesCount', mistakesCount)
    if(+mistakesCount === 0) {
        setStarsCount(5);
        localStorage.setItem('starsCount', 5);
      } else if(+mistakesCount === 1) {
        setStarsCount(4);
        localStorage.setItem('starsCount', 4);
      } else if(+mistakesCount === 2) {
        setStarsCount(3);
         localStorage.setItem('starsCount', 3);
      } else if(+mistakesCount === 3) {
        setStarsCount(2);
         localStorage.setItem('starsCount', 2);
      } else if(+mistakesCount === 4) {
        setStarsCount(1);
         localStorage.setItem('starsCount', 1);
      } else if(+mistakesCount > 4) {
        setStarsCount(0);
         localStorage.setItem('starsCount', 0);
      }
};

export const handleTPM = (rawData, setTypesPerMinute) => {
  localStorage.removeItem('tpm');
  let data = rawData.toFixed(0);
  setTypesPerMinute(data);
  localStorage.setItem('tpm', data);
  console.log('tpm fm function -', data);
};

export const handleLessExercId = (lessonCount, exerciseCount) => {
   let lessExercId = null;
    if(+exerciseCount < 10) {
        lessExercId = `${lessonCount}0${+exerciseCount}`;
      } else {
        lessExercId = `${lessonCount}${+exerciseCount}`;
      }
 return lessExercId;
};

export const handleGrandData = (lessonResults, data) => {
   
    if(lessonResults === []) {
        let grandData = data;
        return grandData;
      } else if(lessonResults?.length > 0) {
        let grandData = lessonResults.push(data);
        return grandData;
      }
};

export const calculateStars = arg => {
    let result = _.sum(
       arg.map(item => ( +item.stars )) );/////////////////////// Суммировать св-во sum в массиве выбранных объектов 
   return result;
 };

 export const calculateTPM = arg => {
    let result = _.sum(
       arg.map(item => ( +item.tpm )) );/////////////////////// Суммировать св-во sum в массиве выбранных объектов 
    
       return +result / +arg.length;
 };

 export const findUnder4Stars = items => {
    let data = items.filter(function(item) {
        return +item.stars < 4 }); 
    let result = data?.map(i => i.id.slice(1, 3)) ;      
     return result;
 };
 
export const handleStarsDesc = (item) => {
    if(+item === 0) {
        return 'зірочок';
     } else if(+item === 1) {
        return 'зірочка';
     } else if(+item > 1) {
        return 'зірочки';
     }
};

export function getStars (lesNum, exNum, keyBoardLayOut) {
 // console.log(lesNum, exNum, keyBoardLayOut);
  if(JSON.parse(localStorage.getItem(`${keyBoardLayOut}Lesson${lesNum}Res`))) {
    let data = JSON.parse(localStorage.getItem(`${keyBoardLayOut}Lesson${lesNum}Res`));
     //console.log(data, exNum, data[exNum].stars);
     if(data[exNum]) {
      let stars = {stars: data[exNum].stars, tpm: data[exNum].tpm};    
      return stars;
     } else {return}
     
  } else {return ;} 
};

export function checkLang (key, charCode, timeCount, setTimeCount, setTrigerErrorMsg) {
  if (     
    /^[a-zA-Z!@#$%^&*{}:;"'`~(),.?<>0-9]$/.test(key) ||
        charCode === 8 ||    
        charCode === 13 || 
        charCode === 16 || 
        charCode === 17 || 
        charCode === 32 ||
        charCode === 187 ||
        charCode === 189 ||
        charCode === 191 ||
        charCode === 192 ||
        charCode === 219 ||
        charCode === 220 ||
        charCode === 221 || 
        charCode === 223 
      ) {
    //console.log('English language mode');      
    if(!timeCount) {setTimeCount(true);}      
  } else {      
     setTimeCount(false);    
     setTrigerErrorMsg(true);
  }
};
export function checkLangForMsg (
               triger, setDesc, setHeader, setPageHeader, 
               exerciseDescEN, setTrigerErrorMsg, lessonHeaderEN,
               exerciseDesc, lessonHeader, exerciseDescAR,
               lessonHeaderAR, trigerErrorMsg, rpt
               ) {
  if (triger === 'en') {
    setDesc(exerciseDescEN);
    setHeader(lessonHeaderEN);
    if (rpt === true) {
      setPageHeader('Repete the Exercise');
    } else {
      setPageHeader('Training');
    }    
  } else if (triger === 'ua') {
    setDesc(exerciseDesc);
    setHeader(lessonHeader);    
    if (rpt === true) {
      setPageHeader('Повторюємо Вправу');
    } else {
      setPageHeader('До Тренерування!');
    } 
  }  else if (triger === 'ar') {
    setDesc(exerciseDescAR);
    setHeader(lessonHeaderAR);    
    if (rpt === true) {
      setPageHeader('Repete the Exercise');
    } else {
      setPageHeader('تدريب');
    } 
  }  
  if (trigerErrorMsg && triger === 'ar') {
    alert('الرجاء التحويل للغه الانجليزيه');
    setTrigerErrorMsg(false);
  } else if (trigerErrorMsg && triger === 'ua') {
    alert('Будь ласка, перейдіть на Англійську розкладку клавіатури');
    setTrigerErrorMsg(false);
  } else if (trigerErrorMsg && triger === 'en') {
    alert('Please switch to the English keyboard layout');
    setTrigerErrorMsg(false);
  }
}

export const handleUnPause = (setPaused, cursPos, setTimeCount) => {  
  setPaused(false);
  cursPos > 0 && setTimeCount(true);
};