import React from 'react';
import { 
       // StarsIcon, 
       // StarHalfFillIcon, 
        StarFillIcon, 
        //StarIcon 
    } from './Icons';
import { motion } from 'framer-motion/dist/framer-motion';
import c from '../views/training_screen.module.css';

const Stars = (props) => {
  return (
    <>
     {+props.starsCount >= 4 ? (
        <motion.div animate={{y: -20, x: 10, scale: 1, transition: {duration: 1.5}}} 
                    initial={{y: -900, x: 0, scale: 0}}
                     whileHover={{ scale: 1.2 }}
                     whileTap={{ scale: 0.9 }}
                     transition={{ type: "spring", stiffness: 400, damping: 17 }}                     
        >
          {StarFillIcon(55, 'var(--yellow-txt')}
        </motion.div>
       ) : ('')
    }

     {+props.starsCount >= 2 ? (
        <motion.div animate={{y: 40, x: 0, scale: 1, transition: {duration: 1.7}}} 
                    initial={{y: -900, x: 0, scale: 0}}
                     whileHover={{ scale: 1.2 }}
                     whileTap={{ scale: 0.9 }}
                     transition={{ type: "spring", stiffness: 400, damping: 17 }} 
                                         
        >
          {StarFillIcon(55, 'var(--yellow-txt')}
        </motion.div>
       ) : ('')
    }

    {+props.starsCount > 4 || +props.starsCount === 1 || +props.starsCount === 3 ? (
        <motion.div animate={{y: 70, x: 0, scale: 1, transition: {duration: 2}}} 
                    initial={{y: -900, x: 0, scale: 0}}
                     whileHover={{ scale: 1.2 }}
                     whileTap={{ scale: 0.9 }}
                     transition={{ type: "spring", stiffness: 400, damping: 17 }}                     
      >
        {StarFillIcon(55, 'var(--yellow-txt')}
      </motion.div>
       ) : ('')
      }
      
      {+props.starsCount >= 2 ? (
         <motion.div animate={{y: 40, x: 0, scale: 1, transition: {duration: 1.7}}} 
                    initial={{y: -900, x: 0, scale: 0}}
                     whileHover={{ scale: 1.2 }}
                     whileTap={{ scale: 0.9 }}
                     transition={{ type: "spring", stiffness: 400, damping: 17 }}                     
         >
           {StarFillIcon(55, 'var(--yellow-txt')}
         </motion.div>
       ) : ('')
    }
     {+props.starsCount >= 4 ? (
        <motion.div animate={{y: -20, x: -10, scale: 1, transition: {duration: 1.5}}} 
                    initial={{y: -900, x: 0, scale: 0}}
                     whileHover={{ scale: 1.2 }}
                     whileTap={{ scale: 0.9 }}
                     transition={{ type: "spring", stiffness: 400, damping: 17 }}                     
         >
          {StarFillIcon(55, 'var(--yellow-txt')}
        </motion.div>
       ) : ('')
    }

    {props.starsCount === 0 ? (
        <div className={c.invi_txt}>
             {StarFillIcon(55, 'var(--invi-txt')}
        </div>
    ) : ('')}
  
    </>
  )
}

export default Stars