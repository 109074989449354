import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import GoHome from '../Components/GoHome';
import RptLesDescr from '../Components/RptLesDescr.js';
import Settings from '../Components/Settings';
import c from './training_screen.module.css';
import PageRegControllers from '../Components/PageRegControllers';
import { FormattedMessage } from 'react-intl';
import defineMessages from '../Lang/languages/messages';



const PreLsnRptNot = (props) => {
    const keyFunction = (event) => {    
        if(event.key === 'Enter' && !props.lessonStarted) {           
          props.handleStart();       
        } 
     };
      useEffect(() => {
          document.addEventListener('keydown', keyFunction);  
        return () => {
          document.removeEventListener('keydown', keyFunction);
        }
    // eslint-disable-next-line 
    }, []);
      return (
        <div className={c.note_wrapper}>
         <PageRegControllers title={`${props.pageHeader}`} noSound={true} />
          <GoHome />
          <Settings />
            <div className={c.note_container}>
              <div className={c.div_80}> 
                       
                   <RptLesDescr 
                    lesson={props.lessonCount}
                    exercise={props.exerciseCount}                    
                    desc={props.desc}                   
                    prevRes={props.prevRes}
                    fiveStars={props.fiveStars}
                   />      
              </div> 
    
              <div className={c.note_container_lower_btn}>
                  <Button variant='success'  onClick={props.handleStart} className={c.rouned_btn} >
                    <span className={c.note_notice_txt}>
                     <FormattedMessage id='startBtn' defaultMessage={defineMessages.startBtn} />
                    </span>
                  </Button>
              </div>           
              
            </div>
        </div>
      )
    }

export default PreLsnRptNot