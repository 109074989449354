import { Button } from 'react-bootstrap';
import c from './post_lesson.module.css';
//import Baloons from '../Components/Baloons';
import CountUp from 'react-countup';
import { motion } from 'framer-motion/dist/framer-motion';

import Stars from '../Components/Stars';

import { FormattedMessage } from 'react-intl';
import defineMessages from '../Lang/languages/messages';



//import Flashes from '../Components/Flashes/Flashes';


const PostLessonNotification = (props) => {

  return (
    <>
    <motion.div className={c.note_wrapper} 
                animate={{y: 0, x: 0, scale: 1, transition: {duration: 1}}} 
                initial={{y: -500, x: 0, scale: 0}}
    >
      
    <div className={`${c.note_container} ${props.rptd ? c.note_container_rptd : ''}`}>
        <div className={c.lesson_header_one}>
        <FormattedMessage id='exerc2' defaultMessage={defineMessages.exerc2} /><br/>           
        <FormattedMessage id='completed' defaultMessage={defineMessages.completed} />
        </div>

         <div className={c.stars_container}>
            <div className={c.stars}>
              <Stars 
               starsCount={props.starsCount}
              /> 
            </div>
         </div>
              
        <div className={c.baloons}>
            {/* <Baloons />              */}
        </div> 
  {/* {props.rptd && props.betterRes ? 
     <div className={c.lesson_header_two}>Результат поліпшено!</div> : 
     <div className={c.lesson_header_three}>Не вдалось поліпшити результат</div>
   }   */}
        <div className={c.results_box} >          
            <div className={c.post_note_notices}>             
                <motion.div className={`${c.note_notice_item} ${c.note_notice_item_color1} ${props.mistakesCount >= 5 && c.red_notice}`}
                            animate={{y: 0, x: 0, scale: 1, transition: {duration: 1.5}}} 
                            initial={{y: 500, x: -1200, scale: 0}}
                 >                  
                  <div className={c.note_notice_txt}>
                  <FormattedMessage id='errors2' defaultMessage={defineMessages.errors2} /> 
                  </div>             
                  <div className={`${c.post_note_count} `}>
                    <CountUp end={props.mistakesCount} delay={1}/>
                  </div> 
                  <span className={c.note_notice_txt2 }>
                    <FormattedMessage id='pcs' defaultMessage={defineMessages.pcs} />
                  </span>
                </motion.div>

                <motion.div className={c.note_notice_item}
                            animate={{y: 0, x: 0, scale: 1, transition: {duration: 1.5}}} 
                            initial={{y: 1000, x: 0, scale: 0}}
                 >                  
                  <div className={`px-3 ${c.note_notice_txt}`}>
                  <FormattedMessage id='stars' defaultMessage={defineMessages.stars} /> 
                  </div>             
                  <div className={c.post_note_count}>
                    <CountUp end={props.starsCount} delay={0}/>
                  </div> 
                  <span className={c.note_notice_txt2 }>
                    <FormattedMessage id='pcs' defaultMessage={defineMessages.pcs} />
                  </span>
                </motion.div>


                <motion.div className={`${c.note_notice_item} ${c.note_notice_item_color2} ${props.typesPerMin <= 49 && c.red_notice}`}
                           animate={{y: 0, x: 0, scale: 1, transition: {duration: 1.5}}} 
                           initial={{y: 500, x: 1200, scale: 0}}
                 >
                 <div className={c.note_notice_txt}>
                 <FormattedMessage id='speed' defaultMessage={defineMessages.speed} /> 
                 </div>             
                 <div className={`${c.post_note_count} `}>
                   <CountUp end={props.typesPerMin} delay={1}/> 
                 </div>
                 <span className={c.note_notice_txt2 }>
                  <FormattedMessage id='simb' defaultMessage={defineMessages.simb} />
                 </span>
               </motion.div>               
            </div>           
        </div>

        {props.starsCount > 0 ? (
          <div className={c.lower_btn_cont}>
           <Button variant='info' className={`my-4 ${c.lower_rouned_btn} ${c.lower_rouned_btn_nerrow}`} onClick={props.handleRptExrc}>
            <span className={c.note_notice_txt}>
             <FormattedMessage id='oneMoreTBtn' defaultMessage={defineMessages.oneMoreTBtn} />
            </span>
           </Button>

           <Button variant='success' className={`my-4 ${c.lower_rouned_btn} ${c.lower_rouned_btn_wider}`} onClick={props.handleContinue}>
            <span className={`mx-4 ${c.note_notice_txt}`}>
             <FormattedMessage id='contBtn' defaultMessage={defineMessages.contBtn} />
            </span>
           </Button>
          </div>
        ) : (
          <div className={c.lower_btn_cont}>
              <Button variant='warning' className={`my-4 ${c.lower_rouned_btn} ${c.lower_rouned_btn_nerrow}`} onClick={props.handleContinue}>
               <span className={` ${c.note_notice_txt}`}>
                <FormattedMessage id='contBtn2' defaultMessage={defineMessages.contBtn2} />                
               </span>
              </Button>

           <Button variant='success' className={`my-4 ${c.lower_rouned_btn} ${c.lower_rouned_btn_wider}`} onClick={props.handleRptExrc}>
            <span className={c.note_notice_txt}>
             <FormattedMessage id='oneMoreTBtn' defaultMessage={defineMessages.oneMoreTBtn} />              
            </span>
           </Button>
          </div>
        )}
        
        
    </div>
    {/* <Flashes /> */}
 </motion.div>

</>
  )
}

export default PostLessonNotification