import React, { useState, useContext, useEffect } from 'react';
import { CircleHalfFillIcon } from './Icons';
//import { Globe } from '../assets/globe2.svg';
import c from './settings.module.css';
import {backgrounds} from './backgrounds.js';
import { ServiceContext } from '../Context/ServiceContext';
import { Context } from '../Lang/LanguageProvider';

import { FormattedMessage } from 'react-intl';
import defineMessages from '../Lang/languages/messages';

 
const Settings = ({ hide }) => {

  const context = useContext(Context); 

  const [counter, setCounter] = useState(25);
  const [go, setGo] = useState(false);

 const { bg, setBg, setTriger } = useContext(ServiceContext);
 const [show, setShow] = useState(false);

 const handleChange = (value) => {
    setBg(value);
    localStorage.setItem('bg', value);

    setGo(false); 
    setTimeout(() => {
      setGo(true);
    }, 1000);     
 };
 const handleShow = () => {
  setShow(!show);
  setGo(!go); 
 };

 
 useEffect(() => {
  if (!bg) {
    setBg('1');
  } else {return};
  // eslint-disable-next-line
 },[]);
//  function mouseL () {
//    alert('exit');
//  };
 //onmouseleave={}



useEffect(() => {     
    if (!go) {
      setCounter(25);
    }
    if (go && counter > 0) {
      setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    }
    if (go && counter === 0) {
      setShow(false);
    }
   // eslint-disable-next-line
}, [show, counter, go]);

useEffect(() => { 
    setCounter(0);
    setGo(false);    
    setCounter(25); 
 // eslint-disable-next-line
}, [bg]);
// {counter}   {go && 'yes'} 

const bgItem = (num, bgNum, name) => {
  return (
    <div className={`${c.bg} ${bgNum}  ${bg === `${num}` && c.outlined}`} onClick={() => handleChange(`${num}`)}>
      {name}
    </div>
  );
};


  return (
    <>
        <select className={c.lang_select_abs_div} onClick={(e) => setTriger(e.target.value)} onChange={context.selectLang} value={context.locale}>                
            <option onClick={() => setTriger('ua')} value="ua"> 🌐 UA</option>
            {/* <option value="ru">🌐 RU</option> */}
            <option onClick={() => setTriger('en')} value="en">🌐 EN</option>
            <option onClick={() => setTriger('ar')} value="ar">🌐 AR</option>
        </select> 
      <div className={c.gear_whele_abs_div} onClick={() => handleShow()}>
        {CircleHalfFillIcon(30)}
      </div>
           
        <div className={`${c.settings_wrapper} ${show ? c.show_wrapper : hide ? c.displayNone : c.hide_wrapper}`}  >
                    
            <div className={c.menu_header}>
            <FormattedMessage id='chooseColour' defaultMessage={defineMessages.chooseColour} /> 
            </div>
            {backgrounds.map((bg, index) => 
              <div key={bg.name}>
                 {bgItem(index+1, bg.bgNum, bg.name)}
              </div>
            )} 
        </div>  
      
    </>
    
  )
}

export default Settings