import { createSlice } from "@reduxjs/toolkit";





export const soundSlice = createSlice({




    name: 'sound',
    initialState: {
        soundOn: true,
       
    },
    reducers: {
        update: (state) => {
            state.soundOn = !state.soundOn;
           localStorage.setItem('soundOff', !state.soundOn);
        },
        // update: (state, action) => {
        //     state.soundOff = action.payload.sound;
        //    // localStorage.setItem('soundOff', action.payload.sound);
        // },
    },
});

export const { update } = soundSlice.actions;
export default soundSlice.reducer;