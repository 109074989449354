import React from 'react';
import useBg from './Hooks/useBg';
import c from './App.module.css';
import { HashRouter as Router, Switch, Route} from 'react-router-dom';
//import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import HomeScreen from './views/HomeScreen';
import TextInputController from './controllers/TextInputController';
import NoPage from './Components/NoPage';
import AllLessonsController from './controllers/AllLessonsController';
//import LessonEndController from './controllers/LessonEndController';
import ExercEndController from './controllers/ExercEndController';
import RptExercController from './controllers/RptExercController';
import RptExercEndController from './controllers/RptExercEndController';
import NewLessonStartController from './controllers/NewLessonStartController';




function App() {

const { activClass } = useBg();


  return (
   
 

     <div className={`${c.App} ${activClass}`}>
      
      <Router>
         <Switch>
          <Route exact path="/" component={HomeScreen}/>
          <Route path="/training" component={TextInputController}/>
          <Route path="/all_lessons" component={AllLessonsController}/>
          {/* <Route path="/lesson_completed" component={LessonEndController}/> */}
          <Route path="/exercise_completed" component={ExercEndController}/>
          <Route path="/exercise_rpt" component={RptExercController}/>
          <Route path="/exercise_repited" component={RptExercEndController}/>
          <Route path="/to_new_power" component={NewLessonStartController}/>
          <Route path="*" component={NoPage}/>
         </Switch>
      </Router>
     </div>
    
  );
}

export default App; 
// {/* <PrivateRoute path="/to_requests" component={TORequests} /> */}


        //    <Switch>
        //   <Route path='/' exect component={App} />
        //   <Route path='/Data' component={Data} />
        //   <Route path='/Agreement' component={Agreement} />
        //   <Route path='/Statement' component={Statement} />          
        //  </Switch>

        //  <Link to='Data'>Заполнить Данные</Link>
        //  <Link to='Agreement'>Перейти к Договору Партнерства</Link>
        //  <Link to='Statement'>Перейти к Акту Приема-Передачи</Link>