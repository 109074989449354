
import c from '../views/training_screen.module.css';
import b from '../views/post_lsn_rpt.module.css';
import { FormattedMessage } from 'react-intl';
import defineMessages from '../Lang/languages/messages';
import {  StarFillIcon  } from './Icons';


const RptLesDescr = ( {lesson, exercise, desc, prevRes, fiveStars} ) => {

 
  const star = StarFillIcon(18, `var(--yellow-txt)`); 
 


  return (
    <div>
      <div className={c.lesson_header_one}>
        Repete
      </div>  <br/>   <br/> 
     <span className={c.lesson_header_two}>    
     <FormattedMessage id='superPower' defaultMessage={defineMessages.superPower} /> {lesson} 
     <br/> 
     <FormattedMessage id='exerc' defaultMessage={defineMessages.exerc} /> 
     <span> {exercise} </span>
    
    </span><br/>
     {/* <span className={c.note_notice_txt}>Тема урока: </span><br/>     
     <div className={c.note_notice_txt_container}>
       <span className={c.note_notice_txt}>{header[lesson - 1]}</span><br/><br/>
     </div>
     */}
    <div className={c.note_notice_txt_container}>
      <div className={` ${c.note_notice_txt}`}>
        {desc[lesson - 1][exercise - 1][0]} {desc[lesson - 1][exercise - 1][1]} <br/>       
      </div> 
     </div>
     <span className={c.lesson_header_two}> {desc[lesson - 1][exercise - 1][2]}</span><br/><br/>


        <div className={b.notice_line_cont}>
                <div className={c.note_notice_txt}>
                    Your Best Result : 
                </div>
                <div className={b.notice_line_star}>
                {fiveStars.map((starNum) => (prevRes.stars >= starNum && <span className='ml-3 pb-2'>{star}</span>))}<br/>
                <span className={b.notice_line_tpm}>{prevRes.tpm} <span className={b.notice_line_tpm_legend}>types/min.</span></span> 
                </div>  
        </div><br/>
    </div>
  )
}

export default RptLesDescr