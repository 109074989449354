import React, { 
    useEffect, useState, useContext
}  from 'react';
import { ServiceContext } from '../Context/ServiceContext';
import c from '../views/home_screen.module.css';
import { useHistory } from "react-router-dom";
import { motion } from 'framer-motion/dist/framer-motion';
import {playSound} from '../Components/PlaySound';
import Schedric from '../assets/Schedric.mp3';
import PageRegControllers from '../Components/PageRegControllers';

import { FormattedMessage } from 'react-intl';
import defineMessages from '../Lang/languages/messages';
//import { Link } from 'react-router-dom';

const HomeScreen = () => {

//const lang = localStorage.getItem('lang'); 
const [schedric, setSchedric] = useState(false);
const [noRepeat, setNoRepeat] = useState(false);
const { soundOff, triger } = useContext(ServiceContext);
//console.log('st-by', schedric, noRepeat);

useEffect(() => {
    if(schedric && !noRepeat && soundOff !== 'yes') {
       // console.log('go', schedric, noRepeat);
        setNoRepeat(true);
        playSound(Schedric);
        setTimeout(() => setNoRepeat(false),40000); 
        setTimeout(() => setSchedric(false),35000);                  
      }
// eslint-disable-next-line 
},[schedric, soundOff]);

const history = useHistory();

const link = (data, load) => {         
        localStorage.setItem('toHighest', load);
    history.push(data);
};
const linkToHighest = (data, load) => {
    localStorage.setItem('exerciseCount', parseInt(lessonExercPassed?.slice(1, 3)));
    localStorage.setItem('lessonCount', lessonExercPassed?.slice(0, 1));
    localStorage.setItem('lessonComplete', 'no');   
      localStorage.setItem('toHighest', load);
  history.push(data);
};
const startLessonOne = () => {
   localStorage.setItem('lessonStarted', false);
   localStorage.setItem('lessonComplete', 'no');
   localStorage.setItem('exerciseCount', 1);
   localStorage.setItem('lessonCount', 1);
   link(`/training`, 'no');
};
//const allLessons = () => {
    // localStorage.setItem('lessonStarted', false);
    // localStorage.setItem('lessonComplete', 'no');
    // localStorage.setItem('exerciseCount', 1);
    // localStorage.setItem('lessonCount', 1);
//     link(`/all_lessons`);
//  };
const lessonExercPassed = localStorage.getItem('lessonExcercPassed');
//console.log(lessonExercPassed?.slice(0, 1), parseInt(lessonExercPassed?.slice(1, 3)));

const [pageHeader, setPageHeader] = useState('Твоя Супер Здібність');
useEffect(() => {    
  if (triger === 'en') {      
    setPageHeader('Your Super Power');
  } else if (triger === 'ua') {
     setPageHeader('Твоя Супер Здібність');
  }  else if (triger === 'ar') {
      setPageHeader('حقق قواك العظمى');
  }    
}, [triger]);

  return (
    <div className={c.home_screen_wrapper}>
        
      <PageRegControllers title={`${pageHeader}`} noHome={true}/>

        <motion.div 
             className={c.logo_container}
             whileHover={{
                originX: 0,
                scale: 1.03,
                textShadow: '0px 0px 2px rgb(255,255,255)',
                color: '#f8e112',                        
            }}
            transition={{ type: 'spring', stiffness: 300 }}
        >
            <span className={`mr-3 ${c.italic_txt}`} >NO TYPO</span> -  <FormattedMessage id='logo' defaultMessage={defineMessages.logo} />
        </motion.div>

        <div className={c.home_screen_options_container}>
            <motion.div 
                    onClick={() => startLessonOne()} 
                    className={`mt-3 ${c.link}`}                   
                    whileHover={{                       
                        scale: 1.1,
                        textShadow: '0px 0px 8px rgb(255,255,255)',
                        color: '#f8e112',                        
                    }}
                    transition={{ type: 'spring', stiffness: 300 }}                   
             >
             <FormattedMessage id='startTraining' defaultMessage={defineMessages.startTraining} />
            </motion.div>


            <motion.div 
                    onClick={() => linkToHighest(`/training`, 'yes')} 
                    className={`mt-3 ${c.link}`}                   
                    whileHover={{                       
                        scale: 1.1,
                        textShadow: '0px 0px 8px rgb(255,255,255)',
                        color: '#f8e112',                        
                    }}
                    transition={{ type: 'spring', stiffness: 300 }}                   
             >
                <FormattedMessage id='goToTraining' defaultMessage={defineMessages.goToTraining} />
             
            </motion.div>
         
            <motion.div 
             onClick={() => link(`/all_lessons`, 'no')}
                   className={`mt-3 ${c.link}`}
                   whileHover={{
                    scale: 1.1,
                    textShadow: '0px 0px 8px rgb(255,255,255)',
                    color: '#f8e112',                        
                }}
                transition={{ type: 'spring', stiffness: 300 }}
            >               
                <FormattedMessage id='allSuperPowers' defaultMessage={defineMessages.allSuperPowers} /><br/>
            </motion.div>
            
      </div>
      {soundOff !== 'yes' ? (
          <motion.div 
                   onClick={() => setSchedric(true)}
                   className={`mt-3 ${c.link}`}
                   whileHover={{
                    scale: 1.1,
                    textShadow: '0px 0px 8px rgb(255,255,255)',
                    color: '#f8e112',                        
                }}
                transition={{ type: 'spring', stiffness: 300 }}
            >
                <FormattedMessage id='schedric' defaultMessage={defineMessages.schedric} />
               <br/>
               <FormattedMessage id='schedric2' defaultMessage={defineMessages.schedric2} /> 
            </motion.div>
      ) : (
        <div className={c.no_sound}>
            <FormattedMessage id='schedric' defaultMessage={defineMessages.schedric} />
             <br/>
            <FormattedMessage id='schedric3' defaultMessage={defineMessages.schedric3} />             
        </div>
      )}
       
          


            <motion.div 
                   onClick={() => link(`/lesson_completed`, 'no')}
                   className={`mt-3 ${c.link}`}
                   whileHover={{
                    scale: 1.1,
                    textShadow: '0px 0px 8px rgb(255,255,255)',
                    color: '#f8e112',                        
                }}
                transition={{ type: 'spring', stiffness: 300 }}
            >
               Lesson End Screen 
            </motion.div>
    </div>
    
  )
}

export default HomeScreen