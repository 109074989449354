import React from 'react';
import c from '../views/home_screen.module.css';
import { useHistory } from "react-router-dom";
import { homeIcon } from './Icons';

const GoHome = () => {

const history = useHistory();
   
  return (
    <div className={c.abs_div} onClick={() => history.push('/')}>
        {homeIcon(50)}
    </div>
  )
}

export default GoHome