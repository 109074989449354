import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import GoHome from '../Components/GoHome';
import LessonDescription from '../Components/LessonDescription';
import Settings from '../Components/Settings';
import c from './training_screen.module.css';

import { FormattedMessage } from 'react-intl';
import defineMessages from '../Lang/languages/messages';



const PreLessonNotification = (props) => {

  const keyFunction = (event) => {    
    if(event.key === 'Enter' && !props.lessonStarted) {           
      props.handleStart();       
    } 
 };
  useEffect(() => {
      document.addEventListener('keydown', keyFunction);  
    return () => {
      document.removeEventListener('keydown', keyFunction);
    }
// eslint-disable-next-line 
}, []);
  return (
    <div className={c.note_wrapper}>
     
      <GoHome />
      <Settings />
        <div className={c.note_container}>
          <div className={c.div_80}>           
               <LessonDescription 
                lesson={props.lessonCount}
                exercise={props.exerciseCount}
                numberOfExerc={props.numberOfExerc}
                desc={props.desc}
                header={props.header}
               />      
          </div> 

          <div className={c.note_container_lower_btn}>
              <Button variant='success'  onClick={props.handleStart} className={c.rouned_btn} >
                <span className={c.note_notice_txt}>
                 <FormattedMessage id='startBtn' defaultMessage={defineMessages.startBtn} />
                </span>
              </Button>
          </div>           
          
        </div>
    </div>
  )
}

export default PreLessonNotification


// {/* <motion.div className={c.rotate_block} drag dragConstraints={{bottom: 0}}>
// <IconContext.Provider value={{ size: "18em", color: 'cadetblue' }}>
//   <GiKeyboard />
// </IconContext.Provider>
// </motion.div>  */}
