import React, {useState, useEffect, useContext } from 'react';
import PreLessonNotification from '../views/PreLessonNotification';
import TrainingScreen from '../views/TrainingScreen';
import { useHistory } from "react-router-dom";
import { ExercContext } from '../Context/ExercContext';
import { ServiceContext } from '../Context/ServiceContext';
import { 
        lessonHeader, 
        exerciseDesc, 
        lessonHeaderEN, 
        exerciseDescEN,
        lessonHeaderAR, 
        exerciseDescAR 
      } from '../models/Tasks';
import PageTitle from '../Components/PageTitle';
import { checkLang, checkLangForMsg } from '../Functions';
import useUnPause from '../Hooks/useUnPause';

const TextInputController = () => {

const { 
        mistakesCount, setMistakesCount, 
        cursPos, setCursPos,
        lessonCount, setLessonCount,
        exerciseCount, setExerciseCount,
        setTypesPerMinute,
        lessonStarted, setLessonStarted,
        numberOfExerc,
        lesson
      }  = useContext(ExercContext);

const history = useHistory();

useEffect(() => {
    if(localStorage.getItem('exerciseCount') > 0) {
        setExerciseCount(localStorage.getItem('exerciseCount'));       
    }  
    if(localStorage.getItem('lessonCount') > 0) {      
      setLessonCount(localStorage.getItem('lessonCount'));      
    } 
  // eslint-disable-next-line  
}, []);

const lessonCompleted = localStorage.getItem('lessonComplete');  

const [timeCount, setTimeCount] = useState(false);
const { paused, handleUnPause, setPaused } = useUnPause();
 
const handleLessonStart = () => {  
    setCursPos(0);
    setMistakesCount(0);
    localStorage.setItem('lessonStarted', true);
    setLessonStarted(true);
//////////////////////////////////////////
   localStorage.setItem('lessonComplete', 'no');
   localStorage.setItem('exerciseCount', exerciseCount);                            
};

/////////////////////////////////////
const handleLessonComplete = () => {
    setCursPos(100);
    localStorage.setItem('mistakeCounter', mistakesCount);
    localStorage.setItem('lessonStarted', false);
    setLessonStarted(false);
    localStorage.setItem('lessonComplete', 'yes');  
    history.push('/exercise_completed'); 
};

const handleForceCompleted = () => {
  localStorage.setItem('lessonStarted', false);
  setLessonStarted(false);
  setTimeCount(false);   
  setMistakesCount(0);  
};
  
const handlePause = () => {
  setTimeCount(false);
  setPaused(true);
};

/////////// Перевірка мови  ///////
const [pageHeader, setPageHeader] = useState('До Тренерування!');
const [trigerErrorMsg, setTrigerErrorMsg] = useState(false);
const { triger } = useContext(ServiceContext);

const [desc, setDesc] = useState(exerciseDesc);
const [header, setHeader] = useState(exerciseDesc);

useEffect(() => { 
  const rpt = false;
  checkLangForMsg(
              triger, setDesc, setHeader, setPageHeader, 
               exerciseDescEN, setTrigerErrorMsg, lessonHeaderEN,
               exerciseDesc, lessonHeader, exerciseDescAR,
               lessonHeaderAR, trigerErrorMsg, rpt
              );   
}, [triger, trigerErrorMsg]);

useEffect(() => {
  //console.log( paused);
  function handleKeyPress(event) {    
    if(event.key === 'Enter' && paused) {  
      console.log(cursPos);         
      handleUnPause(cursPos, setTimeCount);       
    } 
    const charCode = event.which || event.keyCode;      
    const { key } = event;
//console.log(charCode, key) 
    // Check if the pressed key corresponds to an English letter
    checkLang(key, charCode, timeCount, setTimeCount, setTrigerErrorMsg);
  }
  window.addEventListener('keydown', handleKeyPress);
  return () => {
    window.removeEventListener('keydown', handleKeyPress);
  };
  // eslint-disable-next-line
}, []);
//////////////////////////////////////

//console.log(Lesson1[exerciseCount]);

  return (
    <div>
      <PageTitle
       title={`NO Typo | ${pageHeader}`}
      />
        {!lessonStarted && lessonCompleted !== 'yes' && 
             <PreLessonNotification
                lessonCount={lessonCount} 
                exerciseCount={exerciseCount}
                handleStart={handleLessonStart} 
                numberOfExerc={numberOfExerc}
                lessonStarted={lessonStarted}
                desc={desc}
                header={header}
                /> 
            }
            
             
             {lessonStarted && lessonCompleted !== 'yes' &&
               <TrainingScreen 
                 lessonCount={lessonCount} 
                 exerciseCount={exerciseCount}                
                 lesson={lesson[+lessonCount - 1][+exerciseCount - 1]}
                 mistakesCount={mistakesCount}
                 setMistakesCount={setMistakesCount} 
                 cursPos={cursPos}
                 setCursPos={setCursPos}
                 setTypesPerMinute={setTypesPerMinute}  
                 timeCount={timeCount} 
                 setTimeCount={setTimeCount}  
                 paused={paused}
                 numberOfExerc={numberOfExerc} 

                 handleLessonComplete={handleLessonComplete}
                 handleForceCompleted={handleForceCompleted}
                 handlePause={handlePause}
                 handleUnPause={handleUnPause} 
                 
                 desc={desc}
                 header={header} 
                 pageHeader={pageHeader}  
                 
                 rpt={false}
                />     
              }
    </div>
  )
 
}

export default TextInputController


//let number = 1;
//   let findLesson = `lesson_${number}`;

// const test = (arr, item) => { 

//   let data = arr.lesson_1;
//   // let data = arr.filter(function(i) {    
//   //  return i  
//   // }); 
//   return data;
// };
