import { configureStore } from '@reduxjs/toolkit';
import  soundReducer  from './soundSlice';

// export default configureStore({
//     reducer: {
//         rentList: rentReducer,
//         TOJobsList: TOJobsReducer,
//     }
// });

export default configureStore({
    reducer: {
       sound: soundReducer,
    }
});

