import React, { useContext }  from 'react';
import c from './components.module.css';
import { VolumeMuteIcon, VolumeUpIcon } from '../Components/Icons';
import { ServiceContext } from '../Context/ServiceContext';

const SoundControll = () => {

    const {soundOff, setSoundOff} = useContext(ServiceContext);

    const soundControll = () => {
        if(soundOff === 'yes') {
            setSoundOff('no');
            localStorage.setItem('soundOff', 'no');

        } else {
            setSoundOff('yes');
            localStorage.setItem('soundOff', 'yes');
        }
    };


  return (
   <>   
    <div className={c.volume_box} onClick={() => {soundControll()}}>
      {soundOff === 'yes' ? VolumeMuteIcon(35, 'red') : VolumeUpIcon(35)}
    </div>
  </>
  )
}

export default SoundControll