import React, { 
     useState, 
     useEffect, 
     useContext 
   } from 'react';
import { useHistory } from "react-router-dom";
import PostRptExrcNotification from '../views/PostRptExrcNotification';
import { ExercContext } from '../Context/ExercContext';
import { ServiceContext } from '../Context/ServiceContext';
import { handleStarsCount } from '../Functions';
import PageRegControllers from '../Components/PageRegControllers';
import c from '../views/post_lesson.module.css'
import { playSound } from '../Components/PlaySound';
import sound from '../assets/bells.mp3';
import fanfars from '../assets/fanfars.mp3';
import { 
  lessonHeader, 
  exerciseDesc, 
  lessonHeaderEN, 
  exerciseDescEN,
  lessonHeaderAR, 
  exerciseDescAR 
} from '../models/Tasks';
import { checkLang, checkLangForMsg } from '../Functions';
import useCompareRes from '../Hooks/useCompareRes';



const RptExercEndController = () => {


const history = useHistory();
const { keyBoardLayOut }  = useContext(ServiceContext);
const { 
          mistakesCount, setMistakesCount,
          starsCount, setStarsCount,
          setCursPos,
          typesPerMinute, 
          lessonCount,         
      }  = useContext(ExercContext);

localStorage.setItem('tpm', typesPerMinute);
const [pageHeader, setPageHeader] = useState('До Тренерування!');
const [trigerErrorMsg, setTrigerErrorMsg] = useState(false);
const { triger } = useContext(ServiceContext);
// eslint-disable-next-line
const [desc, setDesc] = useState(exerciseDesc);
// eslint-disable-next-line
const [header, setHeader] = useState(exerciseDesc);

useEffect(() => { 
  const rpt = true;
  checkLangForMsg(
              triger, setDesc, setHeader, setPageHeader, 
              exerciseDescEN, setTrigerErrorMsg, lessonHeaderEN,
              exerciseDesc, lessonHeader, exerciseDescAR,
              lessonHeaderAR, trigerErrorMsg, rpt
              );   
}, [triger, trigerErrorMsg]);



const lessonResults = JSON.parse(localStorage.getItem(`${keyBoardLayOut}Lesson${lessonCount}Res`));
const rptExrc = localStorage.getItem('rptExrc');
const rptLsnExrc = localStorage.getItem('rptLsnExrc');

//////////////////////////////////////////////////////
const prevRes = JSON.parse(localStorage.getItem('olderResultsToEdit'));
// localStorage.setItem('formerBestResult', JSON.stringify(lessonResults[rptExrc - 1]));

// useEffect(() => {
//  const test = JSON.parse(localStorage.getItem('formerBestResult'));
//  console.log(test);
// },[]);

////////////////////////////////////////////////////

//console.log(lessonResults, rptExrc, rptLsnExrc);
const [betterRes, setBetterRes] = useState(false);
///////////////////////////////////////////////////////////////////
/// Розраховуємо та записуємо NoTypos
const totalNoTypos = localStorage.getItem('totalNoTypos') ? localStorage.getItem('totalNoTypos') : 0;
const [noTyposEarned, setNoTyposEarned] = useState(0);
const totalTypes = localStorage.getItem('exrsLenght');

console.log(lessonResults[rptExrc - 1], lessonResults);
 useEffect(() => {
  let data = (+totalTypes - +mistakesCount) * (+typesPerMinute / 100); 
  setNoTyposEarned(data.toFixed(0));  
 },[totalTypes, mistakesCount, typesPerMinute]);

/////////////////////////////////////////////////////////////////

///// Встановлює кількість Зірочок
useEffect(() => {
    handleStarsCount(mistakesCount, setStarsCount);
  // eslint-disable-next-line
  }, [mistakesCount]);
///// Оцінює чи збільшилась кількість зірочок у зрівнянні з найбільшим результатом








  useEffect(() => {     
    if(+starsCount > +prevRes.stars) {        
       setBetterRes(true);
  
       
// lessonResults - contins exerc res for this lesson

        const dataToSave = {id: rptLsnExrc, stars: starsCount, tpm: typesPerMinute};
        //const editedData = [...lessonResults.slice(0, rptExrc - 1), dataToSave, ...lessonResults.slice(rptExrc,  lessonResults.length)];
        
        let recordsSorted = lessonResults.filter(obj => obj.id !== rptLsnExrc);
        let newDataToSave = [...recordsSorted, dataToSave].sort((a, b) => a.id.localeCompare(b.id));
      console.log('fm RptExercEndCon line 111', 'dataToSave -', dataToSave); 
        localStorage.setItem(`${keyBoardLayOut}Lesson${lessonCount}Res`, JSON.stringify(newDataToSave));
     } else { 
       setBetterRes(false);
     } 
     // eslint-disable-next-line    
  },[starsCount]);

//console.log(lessonResults[rptExrc - 1]);
const handleContinue = () => {   
    setCursPos(0);
    setMistakesCount(0);
    let data = +totalNoTypos + +noTyposEarned; 
    localStorage.setItem('totalNoTypos', data);
    localStorage.setItem('exrsLenght', 0);
    setNoTyposEarned(0);
    //alert(data); 

    history.push('all_lessons');                 
};
 
const handleRptExrc = () => {
    setCursPos(0);
    setMistakesCount(0);
    let data = +totalNoTypos + +noTyposEarned; 
    localStorage.setItem('totalNoTypos', data);
    localStorage.setItem('exrsLenght', 0);
    setNoTyposEarned(0);
    alert(data);

    history.push('/exercise_rpt');
};


///////////////////////////////////////////////////////////// 
  const keyFunction = (event) => {    
    if(event.key === 'Enter') {           
        handleContinue();       
    } 
 };
  useEffect(() => {
      document.addEventListener('keydown', keyFunction);  
    return () => {
      document.removeEventListener('keydown', keyFunction);
    }        
// eslint-disable-next-line 
}, []);

const { soundOff } = useContext(ServiceContext);
const [compare, setCompare] = useState(false);

useEffect(() => {
    if(+mistakesCount === 0 && typesPerMinute > 50) {
      soundOff !== 'yes' && playSound(fanfars);
    } else {
      soundOff !== 'yes' && playSound(sound);
    } 
    setCompare(!compare);
    // eslint-disable-next-line
    }, []);
///////////////////////////////////////////////////////////////////
const newRes = {stars: starsCount, tpm: typesPerMinute};
const message = useCompareRes(prevRes, newRes, compare);
//console.log(message)


  return (
    <div className={c.wrapper}>
    
    <PageRegControllers 
     title={pageHeader}
     noHome={false}
     noSound={false}
     hide={false}
    />
     
      <PostRptExrcNotification 
        typesPerMin={typesPerMinute}
        mistakesCount={mistakesCount}
        starsCount={starsCount} 
        handleRptExrc={handleRptExrc}
        handleContinue={handleContinue}
        //rptd={true}
        betterRes={betterRes}
        prevRes={prevRes}
        msg={message.message}
      />

    </div>
  )
}

export default RptExercEndController