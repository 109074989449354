import React from 'react';
import GoHome from '../Components/GoHome';
import Settings from '../Components/Settings';
import PageTitle from '../Components/PageTitle';
import SoundControll from '../Components/SoundControll';


const PageRegControllers = ( { title, noHome, noSound, hide, noPageHeader } ) => {
 
  return (
    <>
     {!noPageHeader ? (
      <PageTitle
       title={`NO Typo | ${title}`}
      />
     ) : ('')}
      
      {!noHome ? <GoHome /> : ''}
     
      <Settings hide={hide}/>
      {!noSound ? <SoundControll /> : ''}
    </>
  )
}

export default PageRegControllers