
import { defineMessages } from 'react-intl';

export default defineMessages({
 
  logo: 'Здобудь Cупер Здібність!',
  startTraining: 'Розпочати Тренерування',
  goToTraining: 'До Мого Тренерування',
  allSuperPowers: 'Усі Здібності',
  schedric: 'Щ Е Д Р И К у - 100 років!',
  schedric2: 'Послухати',
  schedric3: 'Увімкніть звук щоб Послухати',
  chooseColour: 'Оберить колір до вподоби',
  startBtn: 'Р о з п о ч а т и',
  contBtn: 'П р о д о в ж и т и',
  contBtn2: 'Продовжити',
  oneMoreTBtn: 'Ще раз',

  superPower: 'Здібність',
  exerc: 'Вправа',
  of: 'з',
  finishg: 'З а к і н ч и т и',
  exerc2: 'В п р а в у',
  pause: 'П А У З А',
  youAreTraining: 'Ви тренеруєте :',
  continue: 'П р о д о в ж и т и',
  errors: 'Кількість Помилок',
  time: 'Використано часу -',
  keyBoard: 'Сховати клавіатуру',
  ShowKeyBoard: 'Показати клавіатуру',
  completed: 'з а к і н ч е н о !',
  errors2: 'ПОМИЛОК',
  stars: 'ЗІРОЧОК',
  speed: 'ШВИДКІСТЬ',
  pcs: 'од.',
  simb: 'знаків на хв.',
  sec: 'c.',
  sorry: 'Пробачте',
  reqPage: 'Запитуєма сторінка',
  notFnd: 'не знайдена',
  toMain:'на головну',
  and: 'та',
  chooseLesAndEx: 'Оберить Здібність та Вправу для тренерування',

           

 
});

   

  // contacts: {
  //   header: "Вы можете связаться со мной",
  //   byPhone: "По телефону: ",
  //   messageIn: "Написать мне в:",
  //   or: "или",
  //   messageForm: "Отправьте мне сообщение",
  //   messageFormName: "Ваше имя", 
  //   messageFormMail: "Ваш email",
  //   messageFormSubj: "Тема",
  //   messageFormMsg: "Ваше сообщение",
  //   messageButton: "Отправить",
  //   visitPage: "Посетите мою страницу в:",

  // }