import b from '../Hooks/use_bg.module.css';

export const backgrounds = [
  {bgNum: `${b.bg_1}`, name: 'Sunset'},
  {bgNum: `${b.bg_2}`, name: 'Titanium'},
  {bgNum: `${b.bg_3}`, name: 'Midnight City'},
  {bgNum: `${b.bg_4}`, name: 'Lawrencium'},
  {bgNum: `${b.bg_5}`, name: 'Frost'},
  {bgNum: `${b.bg_6}`, name: 'Celestial'},
  {bgNum: `${b.bg_7}`, name: 'Lemon Twist'},
  {bgNum: `${b.bg_8}`, name: 'Red Ocean'},
  {bgNum: `${b.bg_9}`, name: 'Love Couple'},
  {bgNum: `${b.bg_10}`, name: 'Dawn'},
  {bgNum: `${b.bg_11}`, name: 'Roseanna'},
  {bgNum: `${b.bg_12}`, name: 'Cocoaa Ice'},
  {bgNum: `${b.bg_13}`, name: 'Rose Colored Lenses'},
  {bgNum: `${b.bg_14}`, name: 'Coal'},
  {bgNum: `${b.bg_15}`, name: 'Visions Of Grandeur'},
  {bgNum: `${b.bg_16}`, name: 'King Yna'},
  {bgNum: `${b.bg_17}`, name: 'Selenium'},
  {bgNum: `${b.bg_18}`, name: 'Sand To Blue'},
  {bgNum: `${b.bg_19}`, name: 'Quepal'},
  {bgNum: `${b.bg_20}`, name: 'Ukraine'},  
];

