import {useState, useEffect, useContext} from 'react';
import { ServiceContext } from '../Context/ServiceContext';
import c from './use_bg.module.css';


const useBg = () => {

const { bg } = useContext(ServiceContext);
const [oliveBg, setOliveBg] = useState(c.bg_olive);
const [greenBtn, setGreenBtn] = useState(c.bg_green);
const [blueBtn, setBlueBtn] = useState(c.bg_blue);
const [rozeBtn, setRozeBtn] = useState(c.bg_roze);
const [orangeBtn, setOrangeBtn] = useState(c.bg_orange);
const [yellowBtn, setYellowBtn] = useState(c.bg_yellow);

const [inputBg, setInputBg] = useState(c.training_input_bg);
const [vertLineBg, setVertLineBg] =useState(c.shade_scr_vert_line_bg1);
const [activClass, setActivClass] = useState(c.bg_1);


useEffect(() => {
    if(bg === '1') {
     setBlueBtn(c.bg_blue);
     setRozeBtn(c.bg_roze);
     setOrangeBtn(c.bg_orange);
     setYellowBtn(c.bg_yellow);
     setGreenBtn(c.bg_green);
     setOliveBg(c.bg_olive);

     setInputBg(c.training_input_bg);

     setVertLineBg(c.shade_scr_vert_line_bg1);

     setActivClass(c.bg_1);
    } else if(bg === '2') {
     setBlueBtn(c.bg_blue2);
     setRozeBtn(c.bg_roze2);
     setOrangeBtn(c.bg_orange2);
     setYellowBtn(c.bg_yellow2);
     setGreenBtn(c.bg_green);
     setOliveBg(c.bg_olive);

     setInputBg(c.training_input_bg);

     setVertLineBg(c.shade_scr_vert_line_bg2);

     setActivClass(c.bg_2);
    } else if(bg === '3') {
     setBlueBtn(c.bg_blue3);
     setRozeBtn(c.bg_roze3);
     setOrangeBtn(c.bg_orange3);
     setYellowBtn(c.bg_yellow3);
     setGreenBtn(c.bg_green3);
     setOliveBg(c.bg_olive3);

     setInputBg(c.training_input_bg3);
     
     setVertLineBg(c.shade_scr_vert_line_bg3);

     setActivClass(c.bg_3);
    }  else if(bg === '4') {
        setBlueBtn(c.bg_blue4);
        setRozeBtn(c.bg_roze4);
        setOrangeBtn(c.bg_orange4);
        setYellowBtn(c.bg_yellow4);
        setGreenBtn(c.bg_green4);
        setOliveBg(c.bg_olive4);
   
        setInputBg(c.training_input_bg3); 

        setVertLineBg(c.shade_scr_vert_line_bg4);

        setActivClass(c.bg_4);
       } else if(bg === '5') {
        setBlueBtn(c.bg_blue3);
        setRozeBtn(c.bg_roze3);
        setOrangeBtn(c.bg_orange3);
        setYellowBtn(c.bg_yellow3);
        setGreenBtn(c.bg_green3);
        setOliveBg(c.bg_olive5);
   
        setInputBg(c.training_input_bg3); 

        setVertLineBg(c.shade_scr_vert_line_bg5);

        setActivClass(c.bg_5);
       } else if(bg === '6') {
        setBlueBtn(c.bg_blue6);
        setRozeBtn(c.bg_roze6);
        setOrangeBtn(c.bg_orange6);
        setYellowBtn(c.bg_yellow6);
        setGreenBtn(c.bg_green6);
        setOliveBg(c.bg_olive6);
   
        setInputBg(c.training_input_bg); 

        setVertLineBg(c.shade_scr_vert_line_bg6);

        setActivClass(c.bg_6);
       } else if(bg === '7') {
        setBlueBtn(c.bg_blue);
        setRozeBtn(c.bg_roze);
        setOrangeBtn(c.bg_orange);
        setYellowBtn(c.bg_yellow);
        setGreenBtn(c.bg_green);
        setOliveBg(c.bg_olive);
   
        setInputBg(c.training_input_bg);
        setVertLineBg(c.shade_scr_vert_line_bg7);
        setActivClass(c.bg_7);
       } else if(bg === '8') {
        setBlueBtn(c.bg_blue);
        setRozeBtn(c.bg_roze);
        setOrangeBtn(c.bg_orange);
        setYellowBtn(c.bg_yellow);
        setGreenBtn(c.bg_green);
        setOliveBg(c.bg_olive);
   
        setInputBg(c.training_input_bg);
        setVertLineBg(c.shade_scr_vert_line_bg8);
        setActivClass(c.bg_8);
       }  else if(bg === '9') {
        setBlueBtn(c.bg_blue);
        setRozeBtn(c.bg_roze);
        setOrangeBtn(c.bg_orange);
        setYellowBtn(c.bg_yellow);
        setGreenBtn(c.bg_green);
        setOliveBg(c.bg_olive);
   
        setInputBg(c.training_input_bg);
        setVertLineBg(c.shade_scr_vert_line_bg9);
        setActivClass(c.bg_9);
       }  else if(bg === '10') {
        setBlueBtn(c.bg_blue);
        setRozeBtn(c.bg_roze);
        setOrangeBtn(c.bg_orange);
        setYellowBtn(c.bg_yellow);
        setGreenBtn(c.bg_green);
        setOliveBg(c.bg_olive);
   
        setInputBg(c.training_input_bg);
        setVertLineBg(c.shade_scr_vert_line_bg10);
        setActivClass(c.bg_10);
       }  else if(bg === '11') {
        setBlueBtn(c.bg_blue);
        setRozeBtn(c.bg_roze);
        setOrangeBtn(c.bg_orange);
        setYellowBtn(c.bg_yellow);
        setGreenBtn(c.bg_green);
        setOliveBg(c.bg_olive);
   
        setInputBg(c.training_input_bg);
        setVertLineBg(c.shade_scr_vert_line_bg11);
        setActivClass(c.bg_11);
       }  else if(bg === '12') {
        setBlueBtn(c.bg_blue);
        setRozeBtn(c.bg_roze);
        setOrangeBtn(c.bg_orange);
        setYellowBtn(c.bg_yellow);
        setGreenBtn(c.bg_green);
        setOliveBg(c.bg_olive);
   
        setInputBg(c.training_input_bg);
        setVertLineBg(c.shade_scr_vert_line_bg12);
        setActivClass(c.bg_12);
       }  else if(bg === '13') {
        setBlueBtn(c.bg_blue);
        setRozeBtn(c.bg_roze);
        setOrangeBtn(c.bg_orange);
        setYellowBtn(c.bg_yellow);
        setGreenBtn(c.bg_green);
        setOliveBg(c.bg_olive);
   
        setInputBg(c.training_input_bg);
        setVertLineBg(c.shade_scr_vert_line_bg13);
        setActivClass(c.bg_13);
       }  else if(bg === '14') {
        setBlueBtn(c.bg_blue);
        setRozeBtn(c.bg_roze);
        setOrangeBtn(c.bg_orange);
        setYellowBtn(c.bg_yellow);
        setGreenBtn(c.bg_green);
        setOliveBg(c.bg_olive);
   
        setInputBg(c.training_input_bg);
        setVertLineBg(c.shade_scr_vert_line_bg14);
        setActivClass(c.bg_14);
       }  else if(bg === '15') {
        setBlueBtn(c.bg_blue);
        setRozeBtn(c.bg_roze);
        setOrangeBtn(c.bg_orange);
        setYellowBtn(c.bg_yellow);
        setGreenBtn(c.bg_green);
        setOliveBg(c.bg_olive);
   
        setInputBg(c.training_input_bg);
        setVertLineBg(c.shade_scr_vert_line_bg15);
        setActivClass(c.bg_15);
       }  else if(bg === '16') {
        setBlueBtn(c.bg_blue);
        setRozeBtn(c.bg_roze);
        setOrangeBtn(c.bg_orange);
        setYellowBtn(c.bg_yellow);
        setGreenBtn(c.bg_green);
        setOliveBg(c.bg_olive);
   
        setInputBg(c.training_input_bg);
        setVertLineBg(c.shade_scr_vert_line_bg16);
        setActivClass(c.bg_16);
       }  else if(bg === '17') {
        setBlueBtn(c.bg_blue);
        setRozeBtn(c.bg_roze);
        setOrangeBtn(c.bg_orange);
        setYellowBtn(c.bg_yellow);
        setGreenBtn(c.bg_green);
        setOliveBg(c.bg_olive);
   
        setInputBg(c.training_input_bg);
        setVertLineBg(c.shade_scr_vert_line_bg17);
        setActivClass(c.bg_17);
       }  else if(bg === '18') {
        setBlueBtn(c.bg_blue);
        setRozeBtn(c.bg_roze);
        setOrangeBtn(c.bg_orange);
        setYellowBtn(c.bg_yellow);
        setGreenBtn(c.bg_green);
        setOliveBg(c.bg_olive);
   
        setInputBg(c.training_input_bg);
        setVertLineBg(c.shade_scr_vert_line_bg18);
        setActivClass(c.bg_18);
       }  else if(bg === '19') {
        setBlueBtn(c.bg_blue);
        setRozeBtn(c.bg_roze);
        setOrangeBtn(c.bg_orange);
        setYellowBtn(c.bg_yellow);
        setGreenBtn(c.bg_green);
        setOliveBg(c.bg_olive);
   
        setInputBg(c.training_input_bg);
        setVertLineBg(c.shade_scr_vert_line_bg19);
        setActivClass(c.bg_19);
       }  else if(bg === '20') {
        setBlueBtn(c.bg_blue);
        setRozeBtn(c.bg_roze);
        setOrangeBtn(c.bg_orange);
        setYellowBtn(c.bg_yellow);
        setGreenBtn(c.bg_green);
        setOliveBg(c.bg_olive);
   
        setInputBg(c.training_input_bg);
        setVertLineBg(c.shade_scr_vert_line_bg20);
        setActivClass(c.bg_20);
       }  
   },[bg]);


    return {
        oliveBg, 
        greenBtn, 
        blueBtn,
        rozeBtn, 
        orangeBtn, 
        yellowBtn,

        inputBg,

        vertLineBg,

        activClass,
    };
};
export default useBg;