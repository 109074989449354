import React, { useState, useEffect, useContext } from 'react';
import { 
        lessonHeader, 
        exerciseDesc, 
        lessonHeaderEN, 
        exerciseDescEN,
        lessonHeaderAR, 
        exerciseDescAR 
       } from '../models/Tasks';
import { useHistory } from "react-router-dom";
import { ServiceContext } from '../Context/ServiceContext';
import { ExercContext } from '../Context/ExercContext';
import AllLessonsView from '../views/AllLessonsView';
import PageRegControllers from '../Components/PageRegControllers';
import c from '../views/post_lesson.module.css';

const AllLessonsController = () => {

const history = useHistory();
const link = (data) => {
    history.push(data);
};
const { keyBoardLayOut, triger }  = useContext(ServiceContext);
const { setRptExrc }  = useContext(ExercContext);
const lessonExercPassed = localStorage.getItem('lessonExcercPassed');
const graduated = localStorage.getItem('graduated') ? localStorage.getItem('graduated') : 0;
const [lessonNum, setLessonNum] = useState('');
const [active, setActive] = useState(false);


const handleLessonNumSet = (num) => {
  setLessonNum(num);
  if(active && num === lessonNum) {
    setActive(false);
  } else {
    setActive(true);
  }
  
};

const linkToHighest = (data, load) => {
  localStorage.setItem('exerciseCount', parseInt(lessonExercPassed?.slice(1, 3)));
  localStorage.setItem('lessonCount', lessonExercPassed?.slice(0, 1));
  localStorage.setItem('lessonComplete', 'no');   
  localStorage.setItem('toHighest', load);
  history.push(data);
};

const handleGoToLesson = (num, num2, lessonNum) => {
console.log(num, num2, lessonNum + 1);
   localStorage.setItem('rptLsnExrc', `${num2 + 1}`); // Це номер вправи для редагування

  let existingLocStorData = JSON.parse(localStorage.getItem(`${keyBoardLayOut}Lesson${lessonNum + 1}Res`));
   
 // console.log(existingLocStorData, lsnNum)
   if(existingLocStorData === null) {
   

    localStorage.setItem('lessonStarted', false);
    localStorage.setItem('lessonComplete', 'no');
    localStorage.setItem('exerciseCount', num);
    localStorage.setItem('lessonCount', lessonNum + 1);
     link(`/training`);
   } else {
     let ecercResultToEdit = existingLocStorData.find(data => +data.id === +num2+1);
    console.log('ecercResultToEdit', ecercResultToEdit);
     if(+lessonExercPassed >= parseInt(`${num2 + 2}`)) {
      console.log('--1--',+lessonExercPassed, +lessonNum + 1, num, '--11--', parseInt(`${num2}`));
      let data = `${num2}`;
      localStorage.setItem('rptLsn', data.slice(0,1));
      localStorage.setItem('rptExrc', num);
     
      setRptExrc(num);
      history.push('/exercise_rpt');        
    } else if(+lessonExercPassed === parseInt(`${num2 + 1}`)) {
        if(parseInt(`${graduated}11`) > parseInt(`${num2 + 1}`)) {
           console.log('Вправа у пройденному Уроці', parseInt(`${graduated}11`), parseInt(`${num2 + 1}`));
           localStorage.setItem('lessonCount', +lessonNum + 1);
           localStorage.setItem('exerciseCount', num);
          // link(`/training`);
          link(`/exercise_rpt`);
        } else {
          console.log('Вправа за межами пройдених Уроків', parseInt(`${graduated}11`), parseInt(`${num2 + 1}`));
          linkToHighest(`/training`, 'yes')
        }       
    } else {
      return;
    }
   }
   
};
//////////////// Language Block //////////////
/////////////////////////////////////////////
const [pageHeader, setPageHeader] = useState('Усі Здібності');
const [desc, setDesc] = useState(exerciseDesc);
const [header, setHeader] = useState(exerciseDesc);

useEffect(() => {    
  if (triger === 'en') {      
     setPageHeader('All Super Powers');
     setDesc(exerciseDescEN);
     setHeader(lessonHeaderEN);
  } else if (triger === 'ua') {
     setPageHeader('Усі Здібності');
     setDesc(exerciseDesc);
     setHeader(lessonHeader);
  }  else if (triger === 'ar') {
      setDesc(exerciseDescAR);
      setHeader(lessonHeaderAR);
      setPageHeader('جميع القوى العظمى');
  }    
}, [triger]);
/////////////////////////////////////////////

    
  return (
    <div className={c.wrapper}>
     <PageRegControllers title={`${pageHeader}`} noSound={true} />

        <AllLessonsView 
         lessonHeader={header}
         exerciseDesc={desc}
         handleLessonNumSet={handleLessonNumSet} 
         handleGoToLesson={handleGoToLesson}
         active={active}
         keyBoardLayOut={keyBoardLayOut}
         lessonNum={lessonNum}
         graduated={graduated}
         lessonExercPassed={lessonExercPassed}

        />

    </div>
  )
}

export default AllLessonsController




