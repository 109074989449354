import React from 'react';
import { Card, Container, Button} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import c from './components.module.css';

import { FormattedMessage } from 'react-intl';
import defineMessages from '../Lang/languages/messages';




const NoPage = () => {

const history = useHistory();

  return (
    <div>
    
      <Container fluid className={c.container} >
     
     <Card className={c.card}>
         <Card.Body>
                <div>
                    <div>
                       <FormattedMessage id='sorry' defaultMessage={defineMessages.sorry} />,<br/>
                        <FormattedMessage id='reqPage' defaultMessage={defineMessages.reqPage} /><br/> 
                        <FormattedMessage id='notFnd' defaultMessage={defineMessages.notFnd} /> 
                    </div>
                     <br />
                  <Button  className={c.no_page_btn} onClick={() => {history.push('/');}} variant='success'>
                   <FormattedMessage id='toMain' defaultMessage={defineMessages.toMain} />
                  </Button>            
                </div>
         </Card.Body>     
       </Card>
     </Container>
     
  </div>
  )
}

export default NoPage