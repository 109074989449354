import React, { useState, 
                useEffect, 
                useContext 
              } from 'react'
import { ExercContext } from '../Context/ExercContext';
import { ServiceContext } from '../Context/ServiceContext';
import TrainingScreen from '../views/TrainingScreen';
import PreLsnRptNot from '../views/PreLsnRptNot';
import { useHistory } from "react-router-dom";
import { 
  lessonHeader, 
  exerciseDesc, 
  lessonHeaderEN, 
  exerciseDescEN,
  lessonHeaderAR, 
  exerciseDescAR 
} from '../models/Tasks';
import { checkLang, checkLangForMsg, getStars } from '../Functions';
import useUnPause from '../Hooks/useUnPause';
import { handleStarsCount } from '../Functions';

const RptExerc = () => {

 const history = useHistory();

 const {
          lesson, 
          mistakesCount, setMistakesCount, 
          cursPos, setCursPos, 
          typesPerMinute, setTypesPerMinute,
          lessonStarted, setLessonStarted,
          numberOfExerc, setStarsCount,     
        }  = useContext(ExercContext);

const { keyBoardLayOut }  = useContext(ServiceContext);
 const { paused, handleUnPause, setPaused } = useUnPause();
 const [timeCount, setTimeCount] = useState(false); 
 const rptLsn = localStorage.getItem('rptLsn');
 const rptExrc = localStorage.getItem('rptExrc');
 const prevRes = getStars(rptLsn, rptExrc - 1, keyBoardLayOut);
 

 const handleLessonStart = () => {  
  setCursPos(0);
  setMistakesCount(0);
  localStorage.setItem('lessonStarted', true);
  setLessonStarted(true);
//////////////////////////////////////////
 localStorage.setItem('lessonComplete', 'no');
 //localStorage.setItem('exerciseCount', exerciseCount);                            
};
 /// Знаходимо данні вправи, яку будемо редагувати /////////////////////////////////
 const dataToEdit = localStorage.getItem('rptLsnExrc'); //Отримаємо номер вправи за для пошуку її результатів
 
 const lessonNum = dataToEdit.slice(0, 1);
 let existingLocStorData= JSON.parse(localStorage.getItem(`${keyBoardLayOut}Lesson${lessonNum}Res`)); 
 let exercResultToEdit = existingLocStorData.find(data => +data.id === +dataToEdit);
   ////// Запишимо в память старі результати вправи для подальшого порівняння з новими
   localStorage.setItem('olderResultsToEdit', JSON.stringify(exercResultToEdit));
  ///////////////////////////////////////////////////////////////////////////// 
 //console.log('existingLocStorData', existingLocStorData, 'exercResultToEdit -', exercResultToEdit);
/////////////////////////////////////////////////////////////////////////////////////////////////////
const [pageHeader, setPageHeader] = useState('До Тренерування!');
const [trigerErrorMsg, setTrigerErrorMsg] = useState(false);
const { triger } = useContext(ServiceContext);
const [desc, setDesc] = useState(exerciseDesc);
const [header, setHeader] = useState(exerciseDesc);
useEffect(() => { 
  const rpt = true;
  checkLangForMsg(
              triger, setDesc, setHeader, setPageHeader, 
               exerciseDescEN, setTrigerErrorMsg, lessonHeaderEN,
               exerciseDesc, lessonHeader, exerciseDescAR,
               lessonHeaderAR, trigerErrorMsg, rpt
              );   
}, [triger, trigerErrorMsg]);

useEffect(() => {
  //console.log( paused);
  function handleKeyPress(event) {    
    if(event.key === 'Enter' && paused) {           
      handleUnPause(cursPos, setTimeCount);       
    } 
    const charCode = event.which || event.keyCode;      
    const { key } = event;
//console.log(charCode, key) 
    // Check if the pressed key corresponds to an English letter
    checkLang(key, charCode, timeCount, setTimeCount, setTrigerErrorMsg);
  }
  window.addEventListener('keydown', handleKeyPress);
  return () => {
    window.removeEventListener('keydown', handleKeyPress);
  };
  // eslint-disable-next-line
}, []);
 useEffect(() => {
  setCursPos(0);
  setMistakesCount(0);
  //localStorage.setItem('lessonStarted', true);
  //setLessonStarted(true);
//////////////////////////////////////////
  localStorage.setItem('lessonComplete', 'no');
 // eslint-disable-next-line 
 }, []);
 
 // const { keyBoardLayOut }  = useContext(ServiceContext);
 //const lessonResults = JSON.parse(localStorage.getItem(`${keyBoardLayOut}Lesson${lessonCount}Res`));
  
 //const dataToEdit = {id: rptLsnExrc, stars: 7, tpm: 300};
 
 
 //console.log(editedData);
 const lessonCompleted = localStorage.getItem('lessonComplete');
 const handleLessonComplete = () => { 
  //alert(lesson[+rptLsn - 1][+rptExrc - 1].length);
     setCursPos(100);
     localStorage.setItem('mistakeCounter', mistakesCount);
     localStorage.setItem('lessonStarted', false);
     setLessonStarted(false);
     localStorage.setItem('lessonComplete', 'yes');  

     handleStarsCount(mistakesCount, setStarsCount);

     localStorage.setItem('tpm', typesPerMinute);
     localStorage.setItem('exrsLenght', lesson[+rptLsn - 1][+rptExrc - 1].length);
     history.push('/exercise_repited');
};
            // useEffect(() => {
            //   if(typesPerMinute !== 0) {  
            //   if(+starsCount > +lessonResults[rptExrc - 1].stars) {   
            //     console.log(+starsCount, +lessonResults[rptExrc - 1].stars);
            //    } else {
            //     console.log(+starsCount, +lessonResults[rptExrc - 1].stars);
            //    }
            //   }  
            // },[starsCount]);

const handleForceCompleted = () => {  
    setLessonStarted(false); 
    localStorage.setItem('lessonStarted', false); 
    history.push('/all_lessons'); 
};

const handlePause = () => {
  setTimeCount(false);
  setPaused(true);
};
const fiveStars = [1,2,3,4,5];

  return (
    <div>     
     
         {!lessonStarted && lessonCompleted !== 'yes' && 
             <PreLsnRptNot
                lessonCount={rptLsn} 
                exerciseCount={rptExrc}
                handleStart={handleLessonStart} 
                numberOfExerc={numberOfExerc}
                lessonStarted={lessonStarted}
                desc={desc}
                header={header}
                prevRes={prevRes}
                fiveStars={fiveStars}
                pageHeader={pageHeader}
                /> 
          }
      {lessonStarted && lessonCompleted !== 'yes' &&   
        <TrainingScreen          
         lessonCount={rptLsn}
         exerciseCount={rptExrc}         
         lesson={lesson[+rptLsn - 1][+rptExrc - 1]}
         mistakesCount={mistakesCount}
         cursPos={cursPos}
         timeCount={timeCount} 
         paused={paused}
                
         setCursPos={setCursPos} 
         setMistakesCount={setMistakesCount}
         setTypesPerMinute={setTypesPerMinute}
         setTimeCount={setTimeCount}
         numberOfExerc={numberOfExerc}

         handleLessonComplete={handleLessonComplete}
         handleForceCompleted={handleForceCompleted}
         handlePause={handlePause}
         handleUnPause={handleUnPause}

         desc={desc}
         header={header} 
         pageHeader={pageHeader} 

         rpt={true}
         prevRes={prevRes}
         fiveStars={fiveStars}
         noPageHeader={true}
        />
    }
    </div>
  )
}

export default RptExerc