import { useEffect, useState } from 'react';
import c from '../views/post_lsn_rpt.module.css';



const useCompareRes = (oldRes, newRes, compare) => {
//console.log(oldRes, newRes)
    const [message, setMessage] = useState('');
    //const [baloons, setBaloons] = useState(false);
    

    useEffect(() => {
    switch (true) {
      case +newRes.stars > +oldRes.stars:
        setMessage(  
            <div className={c.congrats}>
              <div>Congratulation!</div><br/>
              <div>You have improved your result!</div> 
            </div> 
           );
     //      setBaloons(true);
        break;
        case +newRes.stars < +oldRes.stars && +newRes.tpm > +oldRes.tpm:
        setMessage(
            <div>
              <div>You've got better speed, but still,</div><br/>
              <div>You need to make fewer mistakes</div> 
            </div>
        );
        break;
      case +newRes.stars < +oldRes.stars && +newRes.tpm < +oldRes.tpm:
        setMessage(
            <div>
              <div>You were even more succesful</div><br/>
              <div>during your last attempt</div> 
            </div>
        );
        break;  
      case +newRes.stars === +oldRes.stars && +newRes.tpm === +oldRes.tpm:
        setMessage(
             <div className={c.congrats}>
               <div>AMAIZING !!!</div><br/>
               <div>You've got completely same results twice!</div> 
             </div>
          );
        break;
        case +newRes.stars < +oldRes.stars && +newRes.tpm === +oldRes.tpm:
        setMessage(
          <div>
            <div>You were even more succesful</div><br/>
            <div>during your last attempt</div> 
          </div>
          );
        break;
        case +newRes.stars === +oldRes.stars && +newRes.tpm < +oldRes.tpm:
        setMessage(
             <div>
               <div>You were even more succesful</div><br/>
               <div>during your last attempt</div> 
             </div>
          );
        break;
        case +newRes.stars === +oldRes.stars && +newRes.tpm > +oldRes.tpm:
        setMessage(
             <div className={c.congrats}>
               <div>Great work!</div><br/>
               <div>You have improved your typing speed</div> 
             </div>
          );
     //     setBaloons(true);
        break;
       
      default:
        setMessage('No matching case found.');
    }
    // eslint-disable-next-line
  }, [compare]);

    return {
        message,
        // baloons
    };

};
export default useCompareRes;

// case newRes.stars < oldRes.stars && newRes.tpm > oldRes.tpm:
//     setMessage(
//         `<div>
//           <div>'You've got better speed, but still,'</div><br/>
//           <div>'You need to make fewer mistakes'</div>
//         </div>`
//     );
//     break;
//   case newRes.stars < oldRes.stars && newRes.tpm < oldRes.tpm:
//     setMessage(
//         `<div>
//           <div>You were even more succesful</div><br/>
//           <div>during your last attempt</div>
//         </div>`
//     );
//     break;  
//   case newRes.stars === oldRes.stars && newRes.tpm === oldRes.tpm:
//     setMessage(
//          `<div>
//            <div>AMAIZING !!!</div><br/>
//            <div>You've got completely same results twice!</div>
//          </div>`
//       );
//     break;