import React, { createContext, useState } from 'react';


const ServiceContext = createContext();

const ServiceContextProvider = ({children}) => {

    const [soundOff, setSoundOff] = useState(localStorage.getItem('soundOff'));
    const [bg, setBg] = useState(localStorage.getItem('bg'));
    const [keyBoardLayOut, setKeyBoardLayOut] = useState('Lat');
    const [triger, setTriger] = useState('ua');


    return (
        <ServiceContext.Provider
        value={
            {
                soundOff, setSoundOff,
                bg, setBg,
                keyBoardLayOut, setKeyBoardLayOut,
                triger,setTriger
            }
        }
        >
             {children}
        </ServiceContext.Provider>
    )
}

export { ServiceContextProvider, ServiceContext }
