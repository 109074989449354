import React, {useState, useEffect, useRef, useContext } from 'react';
import { ServiceContext } from '../Context/ServiceContext';
import c from './training_screen.module.css';
import { Button } from 'react-bootstrap';
import incorrect from '../assets/incorrect.mp3';
import correct from '../assets/type.mp3';
import {playSound} from '../Components/PlaySound';

import KeyBoard from '../Components/KeyBoard';
import ShadeOverScreen from '../Components/ShadeOverScreen';
import TypingScreenHeader from '../Components/TypingScreenHeader';
import useBg from '../Hooks/useBg';
import PageRegControllers from '../Components/PageRegControllers';
import { handleTPM } from '../Functions';
import { FormattedMessage } from 'react-intl';
import defineMessages from '../Lang/languages/messages';


const TrainingScreen = (props) => {

const inputRef = useRef();

useEffect(() => {
    inputRef.current.focus();
    }, []);

const setOnFocus = () => {   
    inputRef.current.focus();
};  
const {soundOff} = useContext(ServiceContext);

const frame = props.lesson;

//const [inputValue, setInputValue] = useState('');
const inputValue = '';
const [disableInput, setDisableInput] = useState(false);
const [timeElapsed, setTimeElapsed] = useState(0); 

const [enteredValue, setEnteredValue] = useState('');
const [keyBoardError, setKeyBoardError] =useState(false);
const [repetedError, setRepetedError] = useState(false);



const changeHandler = (e) => {
  if(!props.paused){
    //console.log(props.paused);
  setEnteredValue(e.target.value);
 
    props.setTimeCount(true);
   // console.log(e.target.value, frame[props.cursPos]);
    if(e.target.value === frame[props.cursPos]) {
        props.setCursPos(prev => prev + 1);
        setRepetedError(false);
        if(soundOff !== 'yes') {
          playSound(correct);           
        }  
    } else if(e.target.value === ' ' && frame[props.cursPos] === 'Space') {
        props.setCursPos(prev => +prev + 1);
        setRepetedError(false);
        if(soundOff !== 'yes') {
            playSound(correct);
        }      
    } else { if(props.cursPos < frame.length) {      
       
        setKeyBoardError(true);
        setTimeout(() => {setKeyBoardError(false)}, 200);
        if(soundOff !== 'yes') {
           playSound(incorrect);  
        }  
        if (!repetedError) {
          props.setMistakesCount(prev => prev + 1);
         localStorage.setItem('mistakeCounter', props.mistakesCount);
         setRepetedError(true);
        }       
     }       
    }
  } 
};

useEffect(() => {
   
     if(props.cursPos === frame.length) {
        props.setTimeCount(false);
        setDisableInput(true);
       
        let data = frame.length / (timeElapsed / 60);
        handleTPM(data, props.setTypesPerMinute);
        props.handleLessonComplete();      
     }
// eslint-disable-next-line
}, [props.cursPos]);

useEffect(() => {
    if(props.timeCount) {
        setTimeout(() => setTimeElapsed(prev => prev + 1), 1000);
    }
}, [props.timeCount, timeElapsed]);

const {inputBg} = useBg();

  return (
    <div className={c.screen}> 
    <PageRegControllers 
       title={`${props.pageHeader}`} 
       noHome={true} 
       noPageHeader={props.noPageHeader}
    />
  
      <div className={c.div_70vh}>      
        <TypingScreenHeader 
        lessonCount={props.lessonCount}
        exerciseCount={props.exerciseCount}
        mistakesCount={props.mistakesCount}
        timeElapsed={timeElapsed}
        />       
      <div className={c.training_input_wrapper}>
          <div className={`${c.training_input} ${inputBg}`}>
            {frame.map((fr, index) => 
              <> 
                <div className={props.cursPos === index && c.cursor}/> 
                {fr && fr === 'Space' ? 
                (<span className={c.invi_txt}>_</span>) 
                  : 
                (<span className={props.cursPos > index && c.gray_txt}>{fr}</span>)
                } 
              </>
              )}
          </div>
       
            <input ref={inputRef} 
               className={c.typing_input}               
               value={inputValue} 
               disable={disableInput}  
               onChange={(e) => {changeHandler(e)}} 
               onBlur={() => {setOnFocus()}}
             />
        </div>
          <KeyBoard 
            error={keyBoardError}
            value={enteredValue}
            expected={props.cursPos}        
            expVal={frame[props.cursPos]}
          /> 
         {/* <SoundControll />        */}
      </div> 

      <div className={c.footer_btn_cont}>
        <div className={c.footer_btn_box} >           
            <Button variant='outline-light' className={` ${c.rouned_btn}`} onClick={() => props.handleForceCompleted()}>
              <FormattedMessage id='finishg' defaultMessage={defineMessages.finishg} />        
                 <span className={c.invi_txt}> ---</span> 
              <FormattedMessage id='exerc2' defaultMessage={defineMessages.exerc2} />    
            </Button> 
            <Button variant='outline-warning' className={` ${c.btm_btn_one}`} onClick={() => props.handlePause()}>
              <FormattedMessage id='pause' defaultMessage={defineMessages.pause} /> 
            </Button>
        </div> 
      </div>
       {props.paused && 
          <ShadeOverScreen 
           lessonCount={props.lessonCount}
           exerciseCount={props.exerciseCount}
           lesson={props.lessonCount}
           exercise={props.exerciseCount}
           handleUnPause={props.handleUnPause}
           setTimeCount={props.setTimeCount}
           desc={props.desc}
           header={props.header}
           cursPos={props.cursPos}

           rpt={props.rpt}
           prevRes={props.prevRes}
           fiveStars={props.fiveStars}
          />
        }
    </div>
  )
}

export default TrainingScreen

// const keyFunction = () => {
//     //console.log(10, 'code - ', event.code, 'key - ', event.key, frame[props.cursPos]);
//     //console.log(11, cursPos, frame[cursPos]);
//     if (!props.paused) {
//       props.setTimeCount(true);
//     }    
//    };

// useEffect(() => {
//   document.addEventListener('keydown', keyFunction);
//   return () => {
//     document.removeEventListener('keydown', keyFunction);
//   }
//   // eslint-disable-next-line 
// }, []);

   //<div className={c.kebrd_pic}/> 


   // if (go && counter > 0) {
//     setTimeout(() => {
//       setCounter(counter - 1);
//     }, 1000);
//   } 

// const changeHandler = (e) => {
//     setTimeCount(true);
//     //console.log(e.target.value);
//     if(e.key === frame[cursPos]) {
//         setCursPos(prev => prev + 1);       
//     } else if(e.key === 'q' && frame[cursPos] === 'void') {
//         setCursPos(prev => prev + 1);
//     } else {
//         setMistakesCount(prev => prev + 1);
//     }

// };
// const convertKeyEntry = (key, code) => {
//     setTimeCount(true);
    
//     if(key === frame[cursPos]) {
//         setCursPos(prev => prev + 1);       
//     } else if(code === 'Space' && frame[cursPos] === 'Space') {
//         setCursPos(prev => prev + 1);
//     } else {
//         setMistakesCount(prev => prev + 1);
//     }
// };


  //  <div className={cursPos === index && c.cursor}/> */}
  //                <span> {item === 'Space' ? <span className={c.invi_txt}>_</span> : <span className={cursPos > index && c.gray_txt}>{item}</span> } </span>  
          
  //        <div className={c.training_input}>
  //          {frame.map((item, index) => {
  //        <>
  //        <span>{item} </span>
  //         <div className={cursPos === index && c.cursor}/>
  //         <span> {item === 'Space' ? <span className={c.invi_txt}>_</span> : <span className={cursPos > index && c.gray_txt}>{item}</span> } </span>         
  //        </>
  //              })}
  //       </div> 


  // <Button variant='outline-dark' className='mt-4' onClick={() => setOnFocus()}>
  //                   Почати
  //               </Button>


   //convertKeyEntry(event.key, event.code);
  
    // if(event.key === frame[cursPos]) {
    //     console.log('yes');
    //     setCursPos(prev => prev + 1);       
    // } else if(event.code === 'Space' && frame[cursPos] === 'Space') {
    //     setCursPos(prev => prev + 1);
    // } else {
    //     setMistakesCount(prev => prev + 1);
    // }


    // const keyTransfer = (event) => {
//  if(event.code === 'Space') {
//     setInputValue('');
//  } else { 
//     setInputValue(event.key);
//  }
// };






// <div className={c.training_input}>
// <div className={props.cursPos === 0 && c.cursor}/>{frame[0] && frame[0] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 0 && c.gray_txt}>{frame[0]}</span>)} 
// <div className={props.cursPos === 1 && c.cursor}/>{frame[1] && frame[1] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 1 && c.gray_txt}>{frame[1]}</span>)}
// <div className={props.cursPos === 2 && c.cursor}/>{frame[2] && frame[2] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 2 && c.gray_txt}>{frame[2]}</span>)}
// <div className={props.cursPos === 3 && c.cursor}/>{frame[3] && frame[3] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 3 && c.gray_txt}>{frame[3]}</span>)}
// <div className={props.cursPos === 4 && c.cursor}/>{frame[4] && frame[4] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 4 && c.gray_txt}>{frame[4]}</span>)}
// <div className={props.cursPos === 5 && c.cursor}/>{frame[5] && frame[5] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 5 && c.gray_txt}>{frame[5]}</span>)}
// <div className={props.cursPos === 6 && c.cursor}/>{frame[6] && frame[6] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 6 && c.gray_txt}>{frame[6]}</span>)}
// <div className={props.cursPos === 7 && c.cursor}/>{frame[7] && frame[7] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 7 && c.gray_txt}>{frame[7]}</span>)}
// <div className={props.cursPos === 8 && c.cursor}/>{frame[8] && frame[8] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 8 && c.gray_txt}>{frame[8]}</span>)}
// <div className={props.cursPos === 9 && c.cursor}/>{frame[9] && frame[9] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 9 && c.gray_txt}>{frame[9]}</span>)}
// <div className={props.cursPos === 10 && c.cursor}/>{frame[10] && frame[10] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 10 && c.gray_txt}>{frame[10]}</span>)}
// <div className={props.cursPos === 11 && c.cursor}/>{frame[11] && frame[11] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 11 && c.gray_txt}>{frame[11]}</span>)}
// <div className={props.cursPos === 12 && c.cursor}/>{frame[12] && frame[12] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 12 && c.gray_txt}>{frame[12]}</span>)}
// <div className={props.cursPos === 13 && c.cursor}/>{frame[13] && frame[13] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 13 && c.gray_txt}>{frame[13]}</span>)}
// <div className={props.cursPos === 14 && c.cursor}/>{frame[14] && frame[14] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 14 && c.gray_txt}>{frame[14]}</span>)}
// <div className={props.cursPos === 15 && c.cursor}/>{frame[15] && frame[15] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 15 && c.gray_txt}>{frame[15]}</span>)}
// <div className={props.cursPos === 16 && c.cursor}/>{frame[16] && frame[16] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 16 && c.gray_txt}>{frame[16]}</span>)}
// <div className={props.cursPos === 17 && c.cursor}/>{frame[17] && frame[17] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 17 && c.gray_txt}>{frame[17]}</span>)}
// <div className={props.cursPos === 18 && c.cursor}/>{frame[18] && frame[18] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 18 && c.gray_txt}>{frame[18]}</span>)}
// <div className={props.cursPos === 19 && c.cursor}/>{frame[19] && frame[19] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 19 && c.gray_txt}>{frame[19]}</span>)}
// <div className={props.cursPos === 20 && c.cursor}/>{frame[20] && frame[20] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 20 && c.gray_txt}>{frame[20]}</span>)}
// <div className={props.cursPos === 21 && c.cursor}/>{frame[21] && frame[21] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 21 && c.gray_txt}>{frame[21]}</span>)}
// <div className={props.cursPos === 22 && c.cursor}/>{frame[22] && frame[22] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 22 && c.gray_txt}>{frame[22]}</span>)}
// <div className={props.cursPos === 23 && c.cursor}/>{frame[23] && frame[23] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 23 && c.gray_txt}>{frame[23]}</span>)}
// <div className={props.cursPos === 24 && c.cursor}/>{frame[24] && frame[24] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 24 && c.gray_txt}>{frame[24]}</span>)}
// <div className={props.cursPos === 25 && c.cursor}/>{frame[25] && frame[25] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 25 && c.gray_txt}>{frame[25]}</span>)}
// <div className={props.cursPos === 26 && c.cursor}/>{frame[26] && frame[26] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 26 && c.gray_txt}>{frame[26]}</span>)}
// <div className={props.cursPos === 27 && c.cursor}/>{frame[27] && frame[27] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 27 && c.gray_txt}>{frame[27]}</span>)}
// <div className={props.cursPos === 28 && c.cursor}/>{frame[28] && frame[28] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 28 && c.gray_txt}>{frame[28]}</span>)}
// <div className={props.cursPos === 29 && c.cursor}/>{frame[29] && frame[29] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 29 && c.gray_txt}>{frame[29]}</span>)}
// <div className={props.cursPos === 30 && c.cursor}/>{frame[30] && frame[30] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 30 && c.gray_txt}>{frame[30]}</span>)}
// <div className={props.cursPos === 31 && c.cursor}/>{frame[31] && frame[31] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 31 && c.gray_txt}>{frame[31]}</span>)}
// <div className={props.cursPos === 32 && c.cursor}/>{frame[32] && frame[32] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 32 && c.gray_txt}>{frame[32]}</span>)}
// <div className={props.cursPos === 33 && c.cursor}/>{frame[33] && frame[33] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 33 && c.gray_txt}>{frame[33]}</span>)}
// <div className={props.cursPos === 34 && c.cursor}/>{frame[34] && frame[34] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 34 && c.gray_txt}>{frame[34]}</span>)}
// <div className={props.cursPos === 35 && c.cursor}/>{frame[35] && frame[35] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 35 && c.gray_txt}>{frame[35]}</span>)}
// <div className={props.cursPos === 36 && c.cursor}/>{frame[36] && frame[36] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 36 && c.gray_txt}>{frame[36]}</span>)}
// <div className={props.cursPos === 37 && c.cursor}/>{frame[37] && frame[37] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 37 && c.gray_txt}>{frame[37]}</span>)}
// <div className={props.cursPos === 38 && c.cursor}/>{frame[38] && frame[38] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 38 && c.gray_txt}>{frame[38]}</span>)}
// <div className={props.cursPos === 39 && c.cursor}/>{frame[39] && frame[39] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 39 && c.gray_txt}>{frame[39]}</span>)}
// <div className={props.cursPos === 40 && c.cursor}/>{frame[40] && frame[40] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 40 && c.gray_txt}>{frame[40]}</span>)}
// <div className={props.cursPos === 41 && c.cursor}/>{frame[41] && frame[41] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 41 && c.gray_txt}>{frame[41]}</span>)}
// <div className={props.cursPos === 42 && c.cursor}/>{frame[42] && frame[42] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 42 && c.gray_txt}>{frame[42]}</span>)}
// <div className={props.cursPos === 43 && c.cursor}/>{frame[43] && frame[43] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 43 && c.gray_txt}>{frame[43]}</span>)}
// <div className={props.cursPos === 44 && c.cursor}/>{frame[44] && frame[44] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 44 && c.gray_txt}>{frame[44]}</span>)}
// <div className={props.cursPos === 45 && c.cursor}/>{frame[45] && frame[45] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 45 && c.gray_txt}>{frame[45]}</span>)}
// <div className={props.cursPos === 46 && c.cursor}/>{frame[46] && frame[46] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 46 && c.gray_txt}>{frame[46]}</span>)}
// <div className={props.cursPos === 47 && c.cursor}/>{frame[47] && frame[47] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 47 && c.gray_txt}>{frame[47]}</span>)}
// <div className={props.cursPos === 48 && c.cursor}/>{frame[48] && frame[48] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 48 && c.gray_txt}>{frame[48]}</span>)}
// <div className={props.cursPos === 49 && c.cursor}/>{frame[49] && frame[49] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 49 && c.gray_txt}>{frame[49]}</span>)}
// <div className={props.cursPos === 50 && c.cursor}/>{frame[50] && frame[50] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 50 && c.gray_txt}>{frame[50]}</span>)}
// <div className={props.cursPos === 51 && c.cursor}/>{frame[51] && frame[51] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 51 && c.gray_txt}>{frame[51]}</span>)}
// <div className={props.cursPos === 52 && c.cursor}/>{frame[52] && frame[52] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 52 && c.gray_txt}>{frame[52]}</span>)}
// <div className={props.cursPos === 53 && c.cursor}/>{frame[53] && frame[53] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 53 && c.gray_txt}>{frame[53]}</span>)}
// <div className={props.cursPos === 54 && c.cursor}/>{frame[54] && frame[54] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 54 && c.gray_txt}>{frame[54]}</span>)}
// <div className={props.cursPos === 55 && c.cursor}/>{frame[55] && frame[55] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 55 && c.gray_txt}>{frame[55]}</span>)}
// <div className={props.cursPos === 56 && c.cursor}/>{frame[56] && frame[56] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 56 && c.gray_txt}>{frame[56]}</span>)}
// <div className={props.cursPos === 57 && c.cursor}/>{frame[57] && frame[57] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 57 && c.gray_txt}>{frame[57]}</span>)}
// <div className={props.cursPos === 58 && c.cursor}/>{frame[58] && frame[58] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 58 && c.gray_txt}>{frame[58]}</span>)}
// <div className={props.cursPos === 59 && c.cursor}/>{frame[59] && frame[59] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 59 && c.gray_txt}>{frame[59]}</span>)}
// <div className={props.cursPos === 60 && c.cursor}/>{frame[60] && frame[60] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 60 && c.gray_txt}>{frame[60]}</span>)}
// <div className={props.cursPos === 61 && c.cursor}/>{frame[61] && frame[61] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 61 && c.gray_txt}>{frame[61]}</span>)}
// <div className={props.cursPos === 62 && c.cursor}/>{frame[62] && frame[62] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 62 && c.gray_txt}>{frame[62]}</span>)}
// <div className={props.cursPos === 63 && c.cursor}/>{frame[63] && frame[63] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 63 && c.gray_txt}>{frame[63]}</span>)}
// <div className={props.cursPos === 64 && c.cursor}/>{frame[64] && frame[64] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 64 && c.gray_txt}>{frame[64]}</span>)}
// <div className={props.cursPos === 65 && c.cursor}/>{frame[65] && frame[65] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 65 && c.gray_txt}>{frame[65]}</span>)}
// <div className={props.cursPos === 66 && c.cursor}/>{frame[66] && frame[66] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 66 && c.gray_txt}>{frame[66]}</span>)}
// <div className={props.cursPos === 67 && c.cursor}/>{frame[67] && frame[67] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 67 && c.gray_txt}>{frame[67]}</span>)}
// <div className={props.cursPos === 68 && c.cursor}/>{frame[68] && frame[68] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 68 && c.gray_txt}>{frame[68]}</span>)}
// <div className={props.cursPos === 69 && c.cursor}/>{frame[69] && frame[69] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 69 && c.gray_txt}>{frame[69]}</span>)}
// <div className={props.cursPos === 70 && c.cursor}/>{frame[70] && frame[70] === 'Space' ? (<span className={c.invi_txt}>_</span>) : (<span className={props.cursPos > 70 && c.gray_txt}>{frame[70]}</span>)}     
// </div>