import c from './all_lessons.module.css';
import { signpostIcon, ChevronDownIcon2, UnlockIcon, LockIcon, MortorboardIcon, StarFillIcon  } from '../Components/Icons';
import { getStars } from '../Functions';

import { FormattedMessage } from 'react-intl';
import defineMessages from '../Lang/languages/messages';



const AllLessonsView = (props) => {

const star = StarFillIcon(12, `var(--yellow-txt)`);
const fiveStars = [1,2,3,4,5];

  return (
  <div className={c.lessons_wrapper}>
   
     <div className={c.lessons_left_screen_part}>{signpostIcon(100)} </div>


    <div className={c.lesson_list_container}>  
    <h4>
     <FormattedMessage id='chooseLesAndEx' defaultMessage={defineMessages.chooseLesAndEx} />
    </h4>     
      {props.lessonHeader.map((lesson, index) => 
      <div>       
        <div  className={c.lesson_list_spreadout} onClick={() => props.handleLessonNumSet(index)}>
         <div key={lesson} className={c.lesson_list_items} >

            <div className={c.mortorboardIcon}>
              {+props.graduated >= index + 1 ? MortorboardIcon() : <div className={c.place_holder}/>}
            </div>

            <div className={c.lesson_list_index}>
             <FormattedMessage id='superPower' defaultMessage={defineMessages.superPower} /> {index + 1}
            </div>
          
            <div className={c.lesson_list_descr}>
                 <span className='ml-1'> {lesson}</span>                   
            </div>           
                          
          </div> 
            <div           
            className={`${c.lesson_list_arrow} ${props.active && props.lessonNum === index && c.lesson_list_arrow_rotate}`}
            >
              {/* {ArrowUpIcon(25)} */}
              {ChevronDownIcon2(25)}
            </div>            
        </div>         
           
               
                    <div className={`${c.exerc_list}
                           ${props.active && props.lessonNum === index ? 
                           c.exerc_list_container : 
                           c.exerc_list_container_hidden}
                           `}>
                     {props.active && props.exerciseDesc[props.lessonNum].map((desc, index) => 
                     <>
                      <div key={index} 
                          className={`
                          ${+props.lessonExercPassed <= parseInt(`${props.lessonNum  + 1}0${index}`) ? c.locked_content : '' }
                          ${c.exerc_list_item} 
                          ${+props.lessonExercPassed <= parseInt(`${props.lessonNum  + 1}0${index}`) && c.future_lesson}  
                          ${+props.lessonNum === 0 && index === 0  ? c.open_content : '' }              
                          `}
                             onClick={() => {props.handleGoToLesson(
                                                index + 1, 
                                                parseInt(`${props.lessonNum  + 1}0${index}`),
                                                props.lessonNum
                                                // getStars(props.lessonNum + 1, index, props.keyBoardLayOut).stars,
                                                // getStars(props.lessonNum + 1, index, props.keyBoardLayOut).tpm
                                                )}}
                      >
                        <div >
                          <span className={c.lock_icon}>
            {/* Іконка замочка */}
    {/* Умова!!!  */}        {+props.lessonExercPassed <= parseInt(`${props.lessonNum  + 1}0${index}`) ? 
                               (+props.lessonNum + index !== 0 ? LockIcon(18) : UnlockIcon(18)) : 
                               UnlockIcon(18)
                              }  
                          </span>
                          <FormattedMessage id='exerc' defaultMessage={defineMessages.exerc} /> {index + 1} - {desc[1]} - {desc[2]}
                        </div>
                        <div className={c.results_cont}>
                          <div>
                           {fiveStars.map((starNum) => (
                             getStars(props.lessonNum + 1, index, props.keyBoardLayOut)?.stars >= starNum && <span className='ml-1'>{star}</span>
                           ))}
                          </div> 
                          {getStars(props.lessonNum + 1, index, props.keyBoardLayOut)?.tpm} {getStars(props.lessonNum + 1, index, props.keyBoardLayOut)?.tpm && 't/m'} 
                                          
                        </div>
                      </div>
                    </>
                     )}
                    </div>                 
               
         </div>      
        )}
    </div>

  
</div>
  )
}

export default AllLessonsView
 //  ${lessonPassed < (lessonNum  + 1) && c.future_lesson}

// {+lessonExercPassed} - {parseInt(`${lessonNum  + 1}0${index}`)}

// {lessonNum !== '' &&
//     <div className={c.exerc_list_container}>
//         <div 
//          className={`${c.exerc_list_header} ${+lessonExercPassed < parseInt(`${lessonNum + 1}00`) && c.future_lesson}`}
//          >
//             Урок: {lessonNum  + 1}
//         </div> 
//           {exerciseDesc[lessonNum].map((desc, index) => 
//             <div key={index} 
//                  className={`
//                  ${c.exerc_list_item} 
//                  ${+lessonExercPassed <= parseInt(`${lessonNum  + 1}0${index}`) && c.future_lesson}                
//                  `}
//                  onClick={() => {handleGoToLesson(index + 1, parseInt(`${lessonNum  + 1}0${index}`))}}
//              >
//                Вправа {index + 1} - {desc[1]} - {desc[2]}<br/>
             
//             </div>
//           )}
//     </div>
//   }